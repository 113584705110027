import { Font, Page, Text, View, Document, StyleSheet, Image } from "@react-pdf/renderer";
import { useTranslation } from "react-i18next";
import { certificateOfEligibilityList } from "../../../../../common/dropdownList/dropdownData";
import fontNotoSansRegular from "../../../../../assets/fonts/fontsJapan/NotoSansJP-Regular.ttf";
import fontNotoSansRegularItalic from "../../../../../assets/fonts/ARIALI.TTF";
import fontNotoSansBold from "../../../../../assets/fonts/fontsJapan/NotoSansJP-Bold.ttf";
import fontNotoSansBoldItalic from "../../../../../assets/fonts/ARIALBI.TTF";

const TemplateBasic1 = (props) => {
    const { t } = useTranslation("translation");
    Font.register({ family: "NotoSansRegular", src: fontNotoSansRegular });
    Font.register({ family: "NotoSansRegularItalic", src: fontNotoSansRegularItalic });
    Font.register({ family: "NotoSansBold", src: fontNotoSansBold });
    Font.register({ family: "NotoSansBoldItalic", src: fontNotoSansBoldItalic });

    const styles = StyleSheet.create({
        page: {
            backgroundColor: "#FFFFFF",
            fontFamily: "NotoSansRegular"
        },
        image: {
            height: 150,
            width: 150,
            paddingLeft: 20,
            paddingTop: 20
        },
        container: {
            display: "flex",
            flexDirection: "row",
            paddingTop: 5,
            paddingBottom: 5,
            paddingLeft: 20,
            paddingRight: 20
        },
        fullname: {
            paddingTop: 20,
            paddingLeft: 20,
            textAlign: "center",
            color: "#008000",
            fontSize: 28
        },
        titleProfile: {
            width: 300,
            borderTop: 2,
            borderTopColor: "#008000",
            borderBottomColor: "#008000",
            paddingTop: 5
        },
        bodyProfile: {
            width: 300
        },
        upperText: {
            textTransform: "uppercase",
            fontFamily: "NotoSansBold",
            fontSize: 13
        },
        contentText: {
            fontSize: 11
        },
        titleExperience: {
            width: "100%",
            borderTop: 2,
            borderTopColor: "#008000",
            borderBottomColor: "#008000",
            textAlign: "left",
            paddingTop: 5
        },
        bodyExperience: {
            width: "100%",
            borderLeft: 2,
            borderLeftColor: "#008000",
        },
    });

    return (
        <Document>
            <Page size="A4" style={styles.page}>
                <View style={styles.container}>
                    <Image
                        style={styles.image}
                        source={props.data.avatar}
                    />
                    <View style={styles.fullname}>
                        <Text style={{ fontFamily: "NotoSansBold" }}>{props.data.fullName}</Text>
                        <Text style={{ borderBottom: 2, width: 393 }}></Text>
                        <Text style={{ fontFamily: "NotoSansBold", color: "#000000", fontSize: 20 }}>{props.data.otherName}</Text>
                        <View style={{ flexDirection: "row" }}>
                            <View style={{ flexDirection: "column" }}>
                                <Text style={styles.contentText}>Email: {props.data.email}</Text>
                                <Text style={styles.contentText}>Phone: {props.data.phone}</Text>
                                <Text style={styles.contentText}>Gender: {props.data.gender}</Text>
                            </View>
                            <View style={{ flexDirection: "column", paddingLeft: 10 }}>
                                <Text style={styles.contentText}>Marital Status: {props.data.marriage}</Text>
                                <Text style={styles.contentText}>Date of Birth: {props.data.birthDay}</Text>
                                <Text style={styles.contentText}>Age: {props.data.age}</Text>
                            </View>
                        </View>
                    </View>

                </View>
                {/* tile */}
                <View style={styles.container}>
                    <View style={styles.titleProfile}>
                        <Text style={styles.upperText}>HOBBIES, PERSONALITY</Text>
                    </View>
                    <View style={{ paddingLeft: 20 }}></View>
                    <View style={styles.titleProfile}>
                        <Text style={styles.upperText}>HEALTH STATUS</Text>
                    </View>
                    <View style={{ paddingLeft: 20 }}></View>
                    <View style={styles.titleProfile}>
                        <Text style={styles.upperText}>SKILLS</Text>
                    </View>
                </View>
                {/* body */}
                <View style={styles.container}>
                    <View style={styles.bodyProfile}>
                        <Text style={styles.contentText}>Personality: {props.data.personality}</Text>
                        <Text style={styles.contentText}>Favorite subject: {props.data.favoriteSubjects}</Text>
                        <Text style={styles.contentText}>Strengths: {props.data.strongPoints}</Text>
                        <Text style={styles.contentText}>Weaknesses: {props.data.weakPoints}</Text>
                    </View>
                    <View style={{ paddingLeft: 20 }}></View>
                    <View style={styles.bodyProfile}>
                        <Text style={styles.contentText}>Height: {props.data.height}</Text>
                        <Text style={styles.contentText}>Weight: {props.data.weight}</Text>
                        <Text style={styles.contentText}>Blood type: {props.data.bloodType}</Text>
                        <Text style={styles.contentText}>Dominant hand: {props.data.handedness}</Text>
                        <Text style={styles.contentText}>Left Vision: {props.data.eyesight}</Text>
                    </View>
                    <View style={{ paddingLeft: 20 }}></View>
                    <View style={styles.bodyProfile}>
                        <Text style={styles.contentText}>Degree: {props.data.qualifications}</Text>
                        <Text style={styles.contentText}>Japanese: {props.data.japaneseLevel}</Text>
                        <Text style={styles.contentText}>English: {props.data.englishLevel}</Text>
                        <Text style={styles.contentText}>Other languages: {props.data.otherLevel}</Text>
                    </View>
                </View>

                <View style={styles.container}>
                    <View style={styles.titleExperience}>
                        <Text style={styles.upperText}>EDUCATION</Text>
                    </View>
                </View>
                <View style={styles.container}>
                    <View style={{ width: 340, textAlign: "center" }}>
                        <Text style={{ fontFamily: "NotoSansBold", fontSize: 11 }}>Time period</Text>
                    </View>
                    <View style={{ width: 350, textAlign: "center" }}>
                        <Text style={{ paddingLeft: 5, fontFamily: "NotoSansBold", fontSize: 11 }}>School name</Text>
                    </View>
                    <View style={{ width: 210, textAlign: "center" }}>
                        <Text style={{ fontFamily: "NotoSansBold", fontSize: 11, paddingLeft: 10 }}>Major</Text>
                    </View>
                    <View style={{ width: 200, textAlign: "center" }}>
                        <Text style={{ fontFamily: "NotoSansBold", fontSize: 11, paddingLeft: 10 }}>School type</Text>
                    </View>
                    <View style={{ width: 350, textAlign: "center" }}>
                        <Text style={{ fontFamily: "NotoSansBold", fontSize: 11, paddingLeft: 10 }}>Graduation status</Text>
                    </View>
                </View>
                {
                    props.data.userEducations.map((item, key) => {
                        return (
                            <View style={styles.container} key={key}>
                                <View style={{ flexDirection: "column", width: 340 }}>
                                    <View style={{ marginLeft: 2, paddingBottom: 2 }}>
                                        <View style={{ width: 8, height: 8, borderRadius: "50%", backgroundColor: "#006400", marginLeft: -5 }}></View>
                                    </View>
                                    <View style={styles.bodyExperience}>
                                        <Text style={{ paddingLeft: 5, fontSize: 11 }}>{item.enrollmentMonth} ~ {item.graduationMonth}</Text>
                                    </View>
                                </View>
                                <View style={[styles.contentText, { width: 350, textAlign: "center", marginTop: 10, paddingLeft: 10 }]}>
                                    <Text>{item.detail}</Text>
                                </View>
                                <View style={[styles.contentText, { width: 210, paddingLeft: 10, textAlign: "center", marginTop: 10 }]}>
                                    <Text>{item.major}</Text>
                                </View>
                                <View style={[styles.contentText, { width: 200, paddingLeft: 10, textAlign: "center", marginTop: 10 }]}>
                                    <Text>{item.typeSchool.label}</Text>
                                </View>
                                <View style={[styles.contentText, { width: 350, paddingLeft: 10, textAlign: "center", marginTop: 10 }]}>
                                    <Text>{item.graduateStatus.label}</Text>
                                </View>
                            </View>
                        )
                    })
                }
                <View style={styles.container}>
                    <View style={styles.titleExperience}>
                        <Text style={styles.upperText}>WORK EXPERIENCE</Text>
                    </View>
                </View>
                <View style={styles.container}>
                    <View style={{ width: 400, textAlign: "center" }}>
                        <Text style={{ fontFamily: "NotoSansBold", fontSize: 11 }}>Time period</Text>
                    </View>
                    <View style={{ width: 540 }}>
                        <Text style={{ paddingLeft: 5, fontFamily: "NotoSansBold", fontSize: 11 }}>Company name</Text>
                    </View>
                    <View style={{ width: 300, textAlign: "center" }}>
                        <Text style={{ fontFamily: "NotoSansBold", fontSize: 11, paddingLeft: 10 }}>Position</Text>
                    </View>
                </View>
                {
                    props.data.userWorkExps.map((item, key) => {
                        return (
                            <View key={key}>
                                <View style={styles.container} >
                                    <View style={{ flexDirection: "column", width: 400 }}>
                                        <View style={{ marginLeft: 2, paddingBottom: 2 }}>
                                            <View style={{ width: 8, height: 8, borderRadius: "50%", backgroundColor: "#006400", marginLeft: -5 }}></View>
                                        </View>
                                        <View style={styles.bodyExperience}>
                                            <Text style={{ paddingLeft: 5, fontSize: 11 }}>{item.startMonth} ~ {item.endMonth}</Text>
                                        </View>
                                    </View>
                                    <View style={[styles.contentText, { width: 540, marginTop: 10 }]}>
                                        <Text>{item.company}</Text>
                                    </View>
                                    <View style={[styles.contentText, { width: 300, paddingLeft: 10, textAlign: "center", marginTop: 10 }]}>
                                        <Text>{item.position.label}</Text>
                                    </View>

                                </View>
                                <View style={styles.container}>
                                    <Text style={{ fontSize: 11, color: "black", textAlign: "left" }}>{item.jobDesc}</Text>
                                </View>
                            </View>
                        )
                    })
                }

                <View style={styles.container}>
                    <View style={styles.titleExperience}>
                        <Text style={styles.upperText}>PROFESSIONAL SKILLS</Text>
                    </View>
                </View>
                <View style={styles.container}>
                    <View style={{ width: 300, textAlign: "center" }}>
                        <Text style={{ fontFamily: "NotoSansBold", fontSize: 11 }}>Profession</Text>
                    </View>
                    <View style={{ width: 400, textAlign: "center" }}>
                        <Text style={{ paddingLeft: 5, fontFamily: "NotoSansBold", fontSize: 11 }}>Specialization</Text>
                    </View>
                    <View style={{ width: 300, textAlign: "center" }}>
                        <Text style={{ fontFamily: "NotoSansBold", fontSize: 11, paddingLeft: 10 }}>Skills</Text>
                    </View>
                </View>
                {
                    props.data.userSkills.map((item, key) => {
                        return (
                            <View style={styles.container} key={key}>
                                <View style={{ flexDirection: "column", width: 300 }}>
                                    <View style={{ marginLeft: 2, paddingBottom: 2 }}>
                                        <View style={{ width: 8, height: 8, borderRadius: "50%", backgroundColor: "#006400", marginLeft: -5 }}></View>
                                    </View>
                                    <View style={styles.bodyExperience}>
                                        <Text style={{ paddingLeft: 5, fontSize: 11 }}>{item.labelIndustry}</Text>
                                    </View>
                                </View>
                                <View style={[styles.contentText, { width: 500, textAlign: "center", marginTop: 10 }]}>
                                    <Text>{item.labelField}</Text>
                                </View>
                                <View style={[styles.contentText, { width: 300, paddingLeft: 10, textAlign: "justify", marginTop: 10 }]}>
                                    <Text>{item.labelSkill}</Text>
                                </View>
                            </View>
                        )
                    })
                }

                <View style={styles.container}>
                    <View style={styles.titleExperience}>
                        <Text style={styles.upperText}>VISA APPLICATION HISTORY</Text>
                    </View>
                </View>
                {
                    props.data.certificateOfEligibility.value == 2 ? <View style={styles.container}>
                        <View style={{ width: 340, textAlign: "center" }}>
                            <Text style={{ fontFamily: "NotoSansBold", fontSize: 11 }}>Time period</Text>
                        </View>
                        <View style={{ width: 400, textAlign: "center" }}>
                            <Text style={{ paddingLeft: 5, fontFamily: "NotoSansBold", fontSize: 11 }}>Visa type</Text>
                        </View>
                        <View style={{ width: 300, textAlign: "center" }}>
                            <Text style={{ fontFamily: "NotoSansBold", fontSize: 11, paddingLeft: 10 }}>Country of visa application</Text>
                        </View>
                    </View> : <View style={{ width: 300, textAlign: "center" }}>
                        <Text style={{ fontFamily: "NotoSansBold", fontSize: 11 }}>{certificateOfEligibilityList[0].label}</Text>
                    </View>
                }
                {
                    props.data.certificateOfEligibility.value == 2 && props.data.visaHistories.map((item, key) => {
                        return (
                            <View style={styles.container} key={key}>
                                <View style={{ flexDirection: "column", width: 340 }}>
                                    <View style={{ marginLeft: 2, paddingBottom: 2 }}>
                                        <View style={{ width: 8, height: 8, borderRadius: "50%", backgroundColor: "#006400", marginLeft: -5 }}></View>
                                    </View>
                                    <View style={styles.bodyExperience}>
                                        <Text style={{ paddingLeft: 5, fontSize: 11 }}>{item.visaFromDate} ~ {item.visaExpDate}</Text>
                                    </View>
                                </View>
                                <View style={[styles.contentText, { width: 500, textAlign: "center", marginTop: 10 }]}>
                                    <Text>{item.visaType}</Text>
                                </View>
                                <View style={[styles.contentText, { width: 300, paddingLeft: 10, textAlign: "center", marginTop: 10 }]}>
                                    <Text>{item.country}</Text>
                                </View>
                            </View>
                        )
                    })
                }

                <View style={styles.container}>
                    <View style={styles.titleExperience}>
                        <Text style={styles.upperText}>OTHER DESCRIPTIONS</Text>
                    </View>
                </View>
                <View style={styles.container}>
                    <Text style={{ fontSize: 11, color: "black", textAlign: "left", width: 393 }}>{props.data.yourself}</Text>
                </View>
            </Page>
        </Document>
    )
};

export default TemplateBasic1;