import React, { useState, useEffect } from "react";
import { Col, Row } from "reactstrap";
import { Link } from "react-router-dom";
import { Tooltip } from 'antd';
import { useTranslation } from "react-i18next";
import { getCityName, getIconCountry, getJobIcon, removeBackgroundColor, getJobExpireTime, getJobCategory } from "../../services/common";
import { jobTypeList } from "../dropdownList/dropdownData";

const JobItemComponent = (props) => {
    const { t } = useTranslation("translation");
    
    let [jobDetail, setJobDetail] = useState(props.jobDetail);
    let [categories, setCategories] = useState([]);
    let [countryData, setCountryData] = useState([]);
    let [myApplied, setMyApplied] = useState();
    let [cityName, setCityName] = useState('');
    let [jobImage, setJobImage] = useState('');
    let [jobDescription, setJobDescription] = useState('');
    let [jobRequired, setJobRequired] = useState('');
    let [jobIcon, setJobIcon] = useState('');
    let [countryImage, setCountryImage] = useState('');

    useEffect(() => {
        if (props.jobDetail) {
            setJobDetail(props.jobDetail);
        }
        if (props.categories) {
            setCategories(props.categories);
        }
        if (props.countryData) {
            setCountryData(props.countryData);
        }
        if (props.myApplied) {
            setMyApplied(props.myApplied);
        }
    });
    useEffect(() => {
        setCityName(getCityName(countryData, jobDetail.cityId));
        setCountryImage(getIconCountry(countryData, jobDetail.cityId));
    }, [countryData, jobDetail]);

    useEffect(() => {
        setJobImage(jobDetail.image ? jobDetail.image : getJobCategory(categories, jobDetail.catId));
    }, [categories, jobDetail]);

    useEffect(() => {
        setJobDescription(removeBackgroundColor(jobDetail.jobDescription));
        setJobRequired(removeBackgroundColor(jobDetail.jobRequired));
        setJobIcon(getJobIcon(jobDetail));
    }, [jobDetail]);


    let jobPath = '/job/';

    const handleContentTooltip = (jobDetail) => {
        const content = <div style={{ width: "440px" }}>
            <Row className="align-items-center">
                <Col lg={3}>
                    <div className="text-center mb-4 mb-md-0">
                        <img
                            src={getIconCountry(jobDetail.cat)}
                            alt=""
                            className="img-fluid rounded-3"
                            style={{ width: '60px', height: '60px', objectFit: 'cover' }}
                        />
                    </div>
                </Col>
                <Col lg={9}>
                    <div className="mt-2 mb-2 mb-md-0">
                        <h5 className="fs-18 mb-1">
                            <Link to="" className="text-dark">
                                {jobDetail.jobTitle}
                            </Link>
                        </h5>
                        <p className="text-muted fs-14 mb-0">
                            {jobDetail.company}
                        </p>
                    </div>
                    <div className="d-flex mb-2">
                        <div className="flex-shrink-0">
                            <i className="mdi mdi-map-marker text-primary me-1"></i>
                        </div>
                        <p className="text-muted mb-0">{cityName}</p>
                    </div>
                    <div>
                        <p className="text-muted mb-2">
                            <i className="uil uil-usd-circle text-primary me-1"></i> {jobDetail.salary}
                        </p>
                    </div>
                    <div className="mb-2">
                        <span
                            className={jobDetail.jobType === 1 ? "badge bg-success-subtle text-success fs-13 mt-1 mx-1"
                                : "badge bg-danger-subtle text-danger fs-13 mt-1 mx-1"
                            }
                        >
                            {jobDetail.timing}
                        </span>
                    </div>
                </Col>
            </Row >
            <div className="job-content bg-light">
                <div className="mt-4">
                    <h5 className="mb-3 text-dark">{t("jobdetail.motacongviec")}</h5>
                    <div className="job-detail-desc">
                        <div dangerouslySetInnerHTML={{ __html: jobDescription }} />
                    </div>
                </div>

                <div className="mt-4">
                    <h5 className="mb-3 text-dark">{t("jobdetail.yeucaucongviec")}</h5>
                    <div className="job-detail-desc mt-2">
                        <div dangerouslySetInnerHTML={{ __html: jobRequired }} />
                    </div>
                </div>
            </div>
            <div className="mt-1">
                <Row className="align-items-center">
                    <Col md={12}>
                        <Link to={jobPath + jobDetail.id} className="btn btn-primary btn-hover w-100 rounded-4">
                            <i className="mdi mdi-eye"></i> Xem chi tiết
                        </Link>
                    </Col>
                </Row>
            </div>
        </div>
        return content;
    }

    return (
        <React.Fragment>
            <div className="job-box card mt-4">
                {
                    jobIcon &&
                    <div className="bookmark-label text-center">
                        <Link to="#" className="text-white align-middle">
                            <img
                                src={jobIcon}
                                alt=""
                                className="img-fluid rounded-3"
                                style={{ width: '50px', height: '40px', objectFit: 'cover' }}
                            />
                        </Link>
                    </div>
                }
                <Row className="align-items-center">
                    <Col md={3}>
                        <div className="text-center mb-4 mb-md-0">
                            <Link to={jobPath + jobDetail.id}>
                                <img
                                    src={jobImage}
                                    alt=""
                                    className="img-fluid rounded-3"
                                    style={{ width: '60px', height: '60px', objectFit: 'cover' }}
                                />
                            </Link>
                        </div>
                    </Col>
                    <Col md={9}>
                        <div className="mt-2 mb-2 mb-md-0">
                            <Tooltip placement="right" title={handleContentTooltip(jobDetail)} color='white' arrow={false}>
                                <h5 className="fs-18 mb-1">
                                    <Link to={jobPath + jobDetail.id} className="job-homepage primary-link">
                                        {jobDetail.jobTitle}
                                    </Link>
                                </h5>
                                <p className="text-muted fs-14 mb-0">
                                    {jobDetail.company}
                                </p>
                            </Tooltip>
                        </div>

                        <div className="d-flex mb-2">
                            <div className="flex-shrink-0">
                                <i className="mdi mdi-map-marker text-primary me-1"></i>
                            </div>
                            <div style={{ display: "flex", flexDirection: "row" }}>
                                <img src={countryImage} style={{ marginTop: 3 }} height="18" />
                                <p className="job-homepage text-muted mb-0" style={{ paddingLeft: 5 }}>{cityName}</p>
                            </div>
                        </div>
                        <div>
                            <p className="job-homepage text-muted mb-2">
                                <i className="uil uil-usd-circle text-primary me-1"></i> {jobDetail.salary}
                            </p>
                        </div>
                        <div className="mb-2">
                            <span
                                className={jobDetail.jobType === 1 ? "badge bg-success-subtle text-success fs-13 mt-1 mx-1"
                                    : "badge bg-danger-subtle text-danger fs-13 mt-1 mx-1"}
                            >
                                {(jobDetail.jobType === 1 ? jobTypeList[2].label : (jobDetail.jobType === 0 ? jobTypeList[1].label : jobTypeList[0].label))}
                            </span>
                            <span className="text-muted mb-0">
                                <i className="uil uil-clock-three text-primary me-1"></i>{getJobExpireTime(jobDetail.dateNeeded)}
                            </span>
                        </div>
                    </Col>
                </Row>
            </div>
        </React.Fragment>
    )
}

export default JobItemComponent;