export const STORAGE_KEYS = {
  ACCESS_TOKEN: 'dcvpmS00oGGqFVa7pN4akUdfV',
  REFRESH_TOKEN: 'eq63YSIHJDsxZqYGYRjws1OCQpq1',
  EXPIRED_TOKEN: '20231230',
  EMAIL: 'thanhgiang@gmail.com',
  FULL_NAME: 'ThanhGiang',
  PHONE_NUMBER: '0123456789',
  ROLE: 'Admin',
  AVATAR: 'https://wolrdjobstorage.blob.core.windows.net/avatar',
  LANGUAGE: 'vi'
};