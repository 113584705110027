import React, { useState, useEffect, useRef } from "react";
import {
  Col,
  Row,
  Nav,
  NavLink,
  TabContent,
  TabPane,
  Card,
  Input,
  NavItem,
  CardBody,
  Label,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Container,
  Modal, ModalBody, ModalFooter
} from "reactstrap";
import { Icon } from '@iconify/react';
import classnames from "classnames";
import { useNavigate } from "react-router-dom";

//Images Import
import userImage2 from "../../../assets/images/user/img-02.jpg";

import { updateProfile, updateAvatar, getAccountInfo } from "../../../api/apiAccount";
import { MasterContext } from "../../../context/MasterContext";
import { checkPhoneNumberFormat } from "../../../common/checkValidate/checkPhoneNumber";
import { checkFileImageUpload } from "../../../common/checkValidate/checkFileImageUpload";
import Alerts from "../../ExtraPages/Components/Alerts";
import AlertsSuccess from "../../ExtraPages/Components/AlertsSuccess";
import Spinners from "../../ExtraPages/Components/Spinners";
import { MESSAGE_VALIDATE } from "../../../common/constants/Messages";
import { phones } from "../../../common/constants/phoneValidate";
import { STORAGE_KEYS } from "../../../common/constants/Storage";
import { getStorage } from "../../../common/constants/Services";
import { useTranslation } from "react-i18next";
import Select from 'react-select';
import Moment from 'moment';
import flagVn from "../../../assets/images/flags/vietnam.jpg";
import flagJp from "../../../assets/images/flags/japan.jpg";
import flagUs from "../../../assets/images/flags/us.jpg";
import flagGr from "../../../assets/images/flags/germany.jpg";
import flagUk from "../../../assets/images/flags/uk.jpg";
import Section from "./SectionAccount";
import { changePassword } from "../../../api/apiAccount";


const Account = () => {
  const { t } = useTranslation("translation");
  const masterData = React.useContext(MasterContext);
  document.title = "Thông tin tài khoản";
  const [activeTab, setActiveTab] = useState("1");

  const tabChange = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  const navigate = useNavigate();

  const email = getStorage(STORAGE_KEYS.EMAIL);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const toggle = () => setDropdownOpen((prevState) => !prevState);
  const didMount = useRef(false);

  let date = Moment(new Date).add(-20, 'year').format("YYYY-MM-DD");
  let [avatar, setAvatar] = useState('');
  let [avatarPush, setAvatarPush] = useState('');
  let [fullname, setFullName] = useState('');
  let [otherName, setOtherName] = useState('');
  const [flag, setFlag] = useState(flagVn);
  let [codeArea, setCodeArea] = useState('+84');
  let [phone, setPhone] = useState('');
  let [nationality, setNationality] = useState('');
  let [address, setAddress] = useState('');
  let [permanentAddress, setPermanentAddress] = useState('');
  let [countryId, setCountryId] = useState(1);
  let [cityId, setCityId] = useState(1);
  let [countryList, setCountryList] = useState();
  let [cityList, setCityList] = useState();
  let [cityListAll, setCityListAll] = useState();
  let [facebook, setFacebook] = useState('');
  let [twitter, setTwitter] = useState('');
  let [instagram, setInstagram] = useState('');
  let [whatsapp, setWhatsapp] = useState('');
  let [alphabetName, setAlphabetName] = useState('');
  let [birthDay, setBirthDay] = useState(date);
  let [age, setAge] = useState(0);
  let [gender, setGender] = useState('');
  let [marriage, setMarriage] = useState('');
  let [certificateOfEligibility, setCertificateOfEligibility] = useState('');
  let [visaExpDate, setVisaExpDate] = useState(date);
  let [qualifications, setQualifications] = useState('');
  let [japaneseLevel, setJapaneseLevel] = useState();
  let [certificateJapaneseLevelList, setCertificateJapaneseLevelList] = useState();
  let [englishLevel, setEnglishLevel] = useState();
  let [certificateEnglishLevelList, setCertificateEnglishLevelList] = useState();
  let [otherLevel, setOtherLevel] = useState('');
  let [height, setHeight] = useState(0);
  let [weight, setWeight] = useState(0);
  let [bloodType, setBloodType] = useState('');
  let [handedness, setHandedness] = useState('');
  let [eyesight, setEyesight] = useState('');
  let [favoriteSubjects, setFavoriteSubjects] = useState('');
  let [personality, setPersonality] = useState('');
  let [strongPoints, setStrongPoints] = useState('');
  let [weakPoints, setWeakPoints] = useState('');
  let [alertsuccess, setAlertSuccess] = useState(false);
  let [alertdanger, setAlertDanger] = useState("");
  let [alertvalidate, setAlertValidate] = useState(false);
  let [loading, setLoading] = useState(false);
  let [invalid, setInvalid] = useState(true);
  let [avatarErr, setAvatarErr] = useState('');

  const [userEducations, setUserEducations] = useState([
    { detail: '', major: '0', typeSchool: 0, enrollmentMonth: date, graduationMonth: date, graduateStatus: 0 }
  ]);
  const [userWorkExps, setUserWorkExps] = useState([
    { company: '', jobDesc: '', startMonth: date, endMonth: date, position: '0' }
  ]);
  const [userSkills, setUserSkills] = useState([{ industry: 0, labelIndustry: '', field: 0, labelField: '', skill: 0, labelSkill: '' }]);

  const [visaHistories, setVisaHistories] = useState([{ country: { value: 0, label: '' }, visaType: 0, visaFromDate: date, visaExpDate: date }]);

  let [visaCountry, setVisaCountry] = useState(0);
  let [visaCountryLabel, setVisaCountryLabel] = useState('');

  let [industry, setIndustry] = useState(0);
  let [labelIndustry, setLabelIndustry] = useState('');
  let [industryList, setIndustryList] = useState();

  let [fields, setFields] = useState(0);
  let [labelField, setLabelField] = useState('');
  let [fieldsList, setFieldsList] = useState();

  let [skill, setSkill] = useState(0);
  let [labelSkill, setLabelSkill] = useState('');
  let [skillList, setSkillList] = useState();

  let [yourself, setYourSelf] = useState('');

  //modal
  const [modal, setModal] = useState(false);
  const openModal = () => setModal(!modal);

  let [oldPassword, setOldPassword] = useState('');
  let [oldPasswordType, setOldPasswordType] = useState('password');
  let [newPassword, setNewPassword] = useState('');
  let [newPasswordType, setNewPasswordType] = useState('password');
  let [confirmNewPassword, setConfirmNewPassword] = useState('');
  let [confirmNewPasswordType, setConfirmNewPasswordType] = useState('password');

  let [oldPasswordErr, setOldPasswordErr] = useState('');
  let [newPasswordErr, setNewPasswordErr] = useState('');
  let [confirmNewPasswordErr, setConfirmNewPasswordErr] = useState('');
  let [birthDayErr, setBirthDayErr] = useState('');

  const genderList = [
    { value: "0", label: t("gioitinh.khonghienthi") },
    { value: "1", label: t("gioitinh.nam") },
    { value: "2", label: t("gioitinh.nu") },
    { value: "3", label: t("gioitinh.namnu") },
  ];

  const marriageList = [
    { value: "1", label: t("kethon.docthan") },
    { value: "2", label: t("kethon.dakethon") },
    { value: "3", label: t("kethon.dalyhon") },
  ];

  useEffect(() => {
    getAccountInfo().then((res) => {
      if (res) {
        res.avatar ? setAvatar(res.avatar) : setAvatar('');
        res.fullName ? setFullName(res.fullName) : setFullName('');
        res.otherName ? setOtherName(res.otherName) : setOtherName('');
        if (res.phone) {
          let phoneData = res.phone.split('|');
          phoneData[0] ? setCodeArea(phoneData[0]) : setCodeArea('+84');
          phoneData[1] ? setPhone(phoneData[1]) : setPhone('');
        }

        if (res.website) {
          let websiteData = res.website.split('|');
          websiteData[0] ? setFacebook(websiteData[0]) : setFacebook("");
          websiteData[1] ? setInstagram(websiteData[1]) : setInstagram("");
          websiteData[2] ? setTwitter(websiteData[2]) : setTwitter("");
          websiteData[3] ? setWhatsapp(websiteData[3]) : setWhatsapp("");
        }
        res.about ? setYourSelf(res.about) : setYourSelf('');
        res.address ? setAddress(res.address) : setAddress('');
        res.infoData.alphabetName ? setAlphabetName(res.infoData.alphabetName) : setAlphabetName('');
        res.infoData.permanentAddress ? setPermanentAddress(res.infoData.permanentAddress) : setPermanentAddress('');
        res.infoData.birthDay ? setBirthDay(res.infoData.birthDay) : setBirthDay(date);
        if (res.infoData.birthDay) {
          let birthDate = new Date(res.infoData.birthDay);
          let difference = Date.now() - birthDate.getTime();
          let ageDate = new Date(difference);
          let age = Math.abs(ageDate.getUTCFullYear() - 1970);
          setAge(age);
        } else {
          let birthDate = new Date(date);
          let difference = Date.now() - birthDate.getTime();
          let ageDate = new Date(difference);
          let age = Math.abs(ageDate.getUTCFullYear() - 1970);
          setAge(age);
        }

        if (res.infoData.gender) {
          let genderData = genderList.filter(g => g.value == res.infoData.gender);
          if (genderData) {
            setGender({ value: genderData[0].value, label: genderData[0].label });
          }
        } else {
          setGender(genderList[0]);
        }

        if (res.infoData.marriage) {
          let marriageData = marriageList.filter(m => m.value == res.infoData.marriage);
          if (marriageData) {
            setMarriage({ value: marriageData[0].value, label: marriageData[0].label });
          }
        } else {
          setMarriage(marriageList[0]);
        }

        res.infoData.certificateOfEligibility ? setCertificateOfEligibility(res.infoData.certificateOfEligibility) : setCertificateOfEligibility(1);
        res.infoData.visaExpDate ? setVisaExpDate(res.infoData.visaExpDate) : setVisaExpDate(date);
        res.infoData.qualifications ? setQualifications(res.infoData.qualifications) : setQualifications('1');
        res.infoData.otherLevel ? setOtherLevel(res.infoData.otherLevel) : setOtherLevel('');
        res.infoData.height ? setHeight(res.infoData.height) : setHeight(0);
        res.infoData.weight ? setWeight(res.infoData.weight) : setWeight(0);
        res.infoData.bloodType ? setBloodType(res.infoData.bloodType) : setBloodType(1);
        res.infoData.handedness ? setHandedness(res.infoData.handedness) : setHandedness(1);
        res.infoData.eyesight ? setEyesight(res.infoData.eyesight) : setEyesight('1');
        res.infoData.favoriteSubjects ? setFavoriteSubjects(res.infoData.favoriteSubjects) : setFavoriteSubjects('1');
        res.infoData.personality ? setPersonality(res.infoData.personality) : setPersonality(1);
        res.infoData.strongPoints ? setStrongPoints(res.infoData.strongPoints) : setStrongPoints(1);
        res.infoData.weakPoints ? setWeakPoints(res.infoData.weakPoints) : setWeakPoints(1);

        (res.infoData.userEducations && res.infoData.userEducations.length > 0) ? setUserEducations(res.infoData.userEducations) : setUserEducations([
          { detail: '', major: '0', typeSchool: 1, enrollmentMonth: date, graduationMonth: date, graduateStatus: 1 }
        ]);

        (res.infoData.userWorkExps && res.infoData.userWorkExps.length) > 0 ? setUserWorkExps(res.infoData.userWorkExps) : setUserWorkExps([
          { company: '', jobDesc: '', startMonth: date, endMonth: date, position: '1' }
        ]);

        //Get data master
        if (masterData.country) {
          const countryArr = [];
          const cityArr = [];
          const cityListAllArr = [];
          const visaHistorieArr = [];
          masterData.country.map((item, key) => {
            //Get and Set Data
            if (res.infoData.nationality) {
              if (item.id == res.infoData.nationality) {
                setNationality({ value: item.id, label: item.name });
              }
            }
            if (res.cityId && res.infoData.countryId) {
              let cities = item.cities.filter(c => c.countryID == res.infoData.countryId);
              if (cities.length > 0) {
                cities.map((itemcity, keycity) => {
                  if (res.cityId) {
                    if (res.cityId == itemcity.id) {
                      setCountryId({ value: item.id, label: item.name });
                      setCityId({ value: itemcity.id, label: itemcity.name });
                    }
                  }
                  cityArr.push({ value: itemcity.id, label: itemcity.name });
                })
                setCityList(cityArr);
              }
            }
            if (res.infoData.visaHistories != undefined && res.infoData.visaHistories.length > 0 && res.infoData.certificateOfEligibility == 2) {
              res.infoData.visaHistories.map((visa, keyvisa) => {
                if (item.id == visa.counntryID) {
                  visaHistorieArr.push({
                    country: { value: visa.counntryID, label: item.name }, visaId: visa.visaId,
                    visaFromDate: visa.fromDate, visaExpDate: visa.toDate
                  });
                }
              })
              setVisaHistories(visaHistorieArr)
            }
            //Init data
            if (key === 0) {
              if (!res.infoData.nationality) {
                setNationality({ value: item.id, label: item.name });
              }
              setVisaCountry(item.id);
              setVisaCountryLabel(item.name);
              if (res.infoData.visaHistories == undefined || res.infoData.visaHistories.length == 0) {
                setVisaHistories([{ country: { value: item.id, label: item.name }, visaId: 0, visaFromDate: date, visaExpDate: date }]);
              }
              if (!res.cityId && !res.infoData.countryId) {
                let cities = item.cities.filter(c => c.countryID === item.id);
                if (cities.length > 0) {
                  cities.map((itemcity, keycity) => {
                    if (keycity === 0) {
                      setCountryId({ value: item.id, label: item.name });
                      setCityId({ value: itemcity.id, label: itemcity.name });
                    }
                    cityArr.push({ value: itemcity.id, label: itemcity.name });
                  })
                  setCityList(cityArr);
                }
              }
            }
            countryArr.push({ value: item.id, label: item.name });
            cityListAllArr.push(item.cities);
          });
          setCountryList(countryArr);
          setCityListAll(cityListAllArr);
        }
        if (masterData.language) {
          const japanFilter = masterData.language.filter(l => l.levelCode === "JP");
          let japanArr = [{ value: '0', label: t("kinhnghiem.khongyeucau") }];
          let japaneseData = japanFilter.filter(j => j.id == res.infoData.japaneseLevel);
          if (japaneseData.length > 0) {
            setJapaneseLevel({ value: japaneseData[0].id, label: japaneseData[0].levelName });
          } else {
            setJapaneseLevel(japanArr[0]);
          }
          japanFilter.map((item, key) => {
            japanArr.push({ value: item.id, label: item.levelName });
          });
          setCertificateJapaneseLevelList(japanArr);

          const englishFilter = masterData.language.filter(l => l.levelCode === "EN");
          let englishArr = [{ value: '0', label: t("kinhnghiem.khongyeucau") }];
          let englishData = englishFilter.filter(l => l.id == res.infoData.englishLevel);
          if (englishData.length > 0) {
            setEnglishLevel({ value: englishData[0].id, label: englishData[0].levelName });
          } else {
            setEnglishLevel(englishArr[0]);
          }
          englishFilter.map((item, key) => {
            englishArr.push({ value: item.id, label: item.levelName });
          });
          setCertificateEnglishLevelList(englishArr);
        }
        if (masterData.category) {
          if (res.infoData.skills && res.infoData.skills.length > 0) {
            let userSkillArr = [];
            masterData.category.map((item, key) => {
              item.children.map((item2, key2) => {
                item2.children.map((item3, key3) => {
                  res.infoData.skills.map((skill, keyskill) => {
                    if (item3.id == skill.categoryId) {
                      userSkillArr.push({
                        industry: item.id, labelIndustry: item.nameVi, field: item2.id,
                        labelField: item2.nameVi, skill: item3.id, labelSkill: item3.nameVi
                      });
                    }
                  })
                })
              })
            });
            setUserSkills(userSkillArr);

            let industryInit, fieldInit, skillInit = 0;
            let labelIndustryInit, labelFieldInit, labelSkillInit = '';
            let industryArr = [];
            masterData.category.map((item, key) => {
              if (key === 0) {
                industryInit = item.id;
                labelIndustryInit = item.nameVi;
              }
              industryArr.push({ value: item.id, label: item.nameVi, children: item.children });
            });
            setIndustryList(industryArr);

            const fieldFilter = masterData.category.filter(i => i.id === industryInit);
            let fieldArr = [];
            fieldFilter[0].children.map((item, key) => {
              if (key === 0) {
                fieldInit = item.id;
                labelFieldInit = item.nameVi;
              }
              fieldArr.push({ value: item.id, label: item.nameVi, children: item.children });
            });
            setFieldsList(fieldArr);

            const skillFilter = fieldFilter[0].children.filter(i => i.id === fieldInit);
            let skillArr = [];
            skillFilter[0].children.map((item, key) => {
              if (key === 0) {
                skillInit = item.id;
                labelSkillInit = item.nameVi;
              }
              skillArr.push({ value: item.id, label: item.nameVi });
            });
            setSkillList(skillArr);
            setIndustry(industryInit);
            setLabelIndustry(labelIndustryInit);

            setFields(fieldInit);
            setLabelField(labelFieldInit);

            setSkill(skillInit);
            setLabelSkill(labelSkillInit);
          } else {
            let industryInit, fieldInit, skillInit = 0;
            let labelIndustryInit, labelFieldInit, labelSkillInit = '';
            let industryArr = [];
            masterData.category.map((item, key) => {
              if (key === 0) {
                industryInit = item.id;
                labelIndustryInit = item.nameVi;
              }
              industryArr.push({ value: item.id, label: item.nameVi, children: item.children });
            });
            setIndustryList(industryArr);

            const fieldFilter = masterData.category.filter(i => i.id === industryInit);
            let fieldArr = [];
            fieldFilter[0].children.map((item, key) => {
              if (key === 0) {
                fieldInit = item.id;
                labelFieldInit = item.nameVi;
              }
              fieldArr.push({ value: item.id, label: item.nameVi, children: item.children });
            });
            setFieldsList(fieldArr);

            const skillFilter = fieldFilter[0].children.filter(i => i.id === fieldInit);
            let skillArr = [];
            skillFilter[0].children.map((item, key) => {
              if (key === 0) {
                skillInit = item.id;
                labelSkillInit = item.nameVi;
              }
              skillArr.push({ value: item.id, label: item.nameVi });
            });
            setSkillList(skillArr);

            setIndustry(industryInit);
            setLabelIndustry(labelIndustryInit);

            setFields(fieldInit);
            setLabelField(labelFieldInit);

            setSkill(skillInit);
            setLabelSkill(labelSkillInit);

            setUserSkills([{
              industry: industryInit, labelIndustry: labelIndustryInit, field: fieldInit, labelField: labelFieldInit,
              skill: skillInit, labelSkill: labelSkillInit
            }])
          }
        }
      }
    });
  }, []);

  //check validate
  let [fullnameErr, setFullNameErr] = useState('');
  let [phoneErr, setPhoneErr] = useState('');


  const handleUploadAvatar = event => {
    const avatarLoaded = URL.createObjectURL(event.target.files[0]);
    let fileSize = checkFileImageUpload(event.target.files[0]);
    setAvatarPush(event.target.files[0]);
    setAvatar(avatarLoaded);
    if (fileSize) {
      setAvatarErr("");
      (fullnameErr || phoneErr || birthDayErr || (newPassword && (newPassword == oldPassword))
        || (newPassword && (confirmNewPassword != newPassword))) ? setInvalid(true) : setInvalid(false);
    } else {
      setAvatarErr("File upload phải < 2MB");
      setInvalid(true);
    }
  }

  const handleFullName = event => {
    setFullName(event.target.value);
  }

  const handleOtherName = event => {
    setOtherName(event.target.value);
  }

  const handleAge = event => {
    setAge(event.target.value);
  }

  const handleCodeArea = (code, language) => {
    if (language === 'us') {
      setFlag(flagUs);
    }
    if (language === 'uk') {
      setFlag(flagUk);
    }
    if (language === 'vi') {
      setFlag(flagVn);
    }
    if (language === 'jp') {
      setFlag(flagJp);
    }
    if (language === 'ge') {
      setFlag(flagGr);
    }
    setCodeArea(code);
  }

  const handlePhone = event => {
    setPhone(event.target.value);
  }

  const handleAddress = event => {
    setAddress(event.target.value);
  }

  const handlePermanentAddress = event => {
    setPermanentAddress(event.target.value);
  }

  const handleNationality = event => {
    setNationality({ value: event.value, label: event.label });
  }

  const handleCountryId = event => {
    setCountryId({ value: event.value, label: event.label });
    cityListAll.map((item, key) => {
      const citieDropdown = item.filter(c => c.countryID == event.value);
      if (citieDropdown.length > 0) {
        const cityListArr = [];
        citieDropdown.map((itemcity, keycity) => {
          if (keycity === 0) {
            setCityId({ value: itemcity.id, label: itemcity.name });
          }
          cityListArr.push({ value: itemcity.id, label: itemcity.name });
        });
        setCityList(cityListArr);
      }
    });
  }

  const handleCity = event => {
    setCityId({ value: event.value, label: event.label });
  }

  const handleFacebook = event => {
    setFacebook(event.target.value);
  }

  const handleInstagram = event => {
    setInstagram(event.target.value);
  }

  const handleTwitter = event => {
    setTwitter(event.target.value);
  }

  const handleWhatsApp = event => {
    setWhatsapp(event.target.value);
  }

  const handleBirthDay = event => {
    let birthDate = new Date(event.target.value);
    let difference = Date.now() - birthDate.getTime();
    let ageDate = new Date(difference);
    let age = Math.abs(ageDate.getUTCFullYear() - 1970);
    setBirthDay(event.target.value);
    setAge(age);
    let birthDayCheck = false;
    if (Moment(event.target.value).format("YYYYMMDD") > Moment(new Date).format("YYYYMMDD")) {
      setBirthDayErr("Ngày sinh không được lớn hơn ngày hiện tại");
      birthDayCheck = true;
    } else {
      setBirthDayErr("");
    }
    (fullnameErr || phoneErr || birthDayCheck || avatarErr) ? setInvalid(true) : setInvalid(false);
  }

  const handleGender = event => {
    setGender({ value: event.value, label: event.label });
  }

  const handleMarriage = event => {
    setMarriage({ value: event.value, label: event.label });
  }

  const handleOldPassword = event => {
    setOldPassword(event.target.value);
  }

  const handleShowOldPassword = () => {
    oldPasswordType === 'password' ? setOldPasswordType('text') : setOldPasswordType('password');
  }

  const handleNewPassword = event => {
    setNewPassword(event.target.value);
    if (event.target.value == oldPassword) {
      setNewPasswordErr("Mật khẩu mới không được trùng mới mật khẩu cũ");
    } else {
      setNewPasswordErr("");
    }
    (fullnameErr || phoneErr || birthDayErr || (event.target.value == oldPassword) || avatarErr) ? setInvalid(true) : setInvalid(false);
  }

  const handleShowNewPassword = () => {
    newPasswordType === 'password' ? setNewPasswordType('text') : setNewPasswordType('password');
  }

  const handleConfirmNewPassword = event => {
    setConfirmNewPassword(event.target.value);
    if (event.target.value != newPassword) {
      setConfirmNewPasswordErr("Xác nhận mật khẩu mới không đúng");
    } else {
      setConfirmNewPasswordErr("");
    }
    (fullnameErr || phoneErr || birthDayErr || newPassword == oldPassword || event.target.value != newPassword || avatarErr) ? setInvalid(true) : setInvalid(false);
  }

  const handleShowConfirmNewPassword = () => {
    confirmNewPasswordType === 'password' ? setConfirmNewPasswordType('text') : setConfirmNewPasswordType('password');
  }

  const background_color = '#ffffff';

  useEffect(() => {
    if (!didMount.current) {
      return () => {
        didMount.current = true;
      };
    }
    checkValidate('fullname');
  }, [fullname]);

  useEffect(() => {
    if (!didMount.current) {
      return () => {
        didMount.current = true;
      };
    }
    checkValidate('phone');
  }, [phone]);

  const checkValidate = (value) => {
    let checkFullName = true;
    let checkPhone = true;
    if (value === 'fullname') {
      if (!fullname) {
        setFullNameErr(t("fulllNameValidate"));
        checkFullName = true;
      } else {
        setFullNameErr('');
        checkFullName = false;
      }
      (!checkPhoneNumberFormat(phone)) ? checkPhone = true : checkPhone = false;
    }
    if (value === 'phone') {
      if ((!phone && !checkPhoneNumberFormat(phone, phones["vi-VN"])) || phone.length > 10 || phone.length < 9) {
        setPhoneErr(t("phoneValidate"));
        checkPhone = true;
      } else {
        setPhoneErr('');
        checkPhone = false;
      }
      !fullname ? checkFullName = true : checkFullName = false;
    }
    (checkFullName || checkPhone || birthDayErr) ? setInvalid(true) : setInvalid(false);
  }

  const handleUpdateProfile = async () => {
    const userEducationData = userEducations.filter(u => u.detail == '');
    const userWorkExpData = userWorkExps.filter(u => u.company == '');
    if ((userEducationData.length > 0 && userEducations.length > 1) || (userWorkExpData.length > 0 && userWorkExps.length > 1)) {
      setAlertValidate(true);
      return;
    } else {
      setAlertValidate(false);
    }

    let skillData = [];
    userSkills.length > 0 && userSkills.map((item, key) => {
      let data = {
        categoryId: Number(item.skill),
        category: {
          id: Number(item.skill),
          nameVi: item.labelSkill,
          nameEn: null,
          nameDe: null,
          nameJp: null,
          nameKr: null,
          parent: null,
          children: null
        }
      }
      skillData.push(data);
    });

    let visaHistoriesData = [];
    if (visaHistories.length > 0 && certificateOfEligibility == 2) {
      visaHistories.map((item, key) => {
        visaHistoriesData.push({
          counntryID: item.country.value, visaId: Number(item.visaId),
          fromDate: item.visaFromDate, toDate: item.visaExpDate
        });
      })
    }

    let employeeInfo = {
      alphabetName: alphabetName,
      permanentAddress: permanentAddress,
      birthDay: birthDay,
      gender: Number(gender.value),
      marriage: Number(marriage.value),
      certificateOfEligibility: certificateOfEligibility.toString(),
      nationality: nationality.value,
      countryId: countryId.value,
      visaExpDate: visaExpDate,
      qualifications: qualifications,
      japaneseLevel: japaneseLevel.value.toString(),
      englishLevel: englishLevel.value.toString(),
      otherLevel: otherLevel,
      height: Number(height),
      weight: Number(weight),
      bloodType: Number(bloodType),
      handedness: Number(handedness),
      eyesight: eyesight,
      favoriteSubjects: favoriteSubjects,
      personality: Number(personality),
      strongPoints: Number(strongPoints),
      weakPoints: Number(weakPoints),
      graduateStatus: 0,
      userEducations: (userEducationData.length > 0 && userEducations.length == 1) ? [] : userEducations,
      userWorkExps: (userWorkExps.length > 0 && userWorkExps.length == 1) ? [] : userWorkExps,
      skills: skillData,
      visaHistories: visaHistoriesData
    };
    let website = facebook + '|' + instagram + '|' + twitter + '|' + whatsapp;
    setAlertDanger("");
    setAlertSuccess(false);
    setLoading(true);

    let conditionUpdate = 0;
    if (oldPassword && newPassword) {
      await changePassword(email, oldPassword, newPassword).then((res) => {
        if (res.status == 200) {
          conditionUpdate = 1;
          setLoading(false);
          return 0;
        } else {
          conditionUpdate = 2;
          setAlertDanger("changePassword " + JSON.stringify(res));
          setLoading(false);
        }
      }, (err) => {
        setAlertDanger("changePassword " + JSON.stringify(err));
      });
    }

    if (conditionUpdate === 0 || conditionUpdate === 1) {
      await updateProfile(fullname, otherName, codeArea + '|' + phone, address, cityId.value, website, yourself, employeeInfo).then((res) => {
        if (res.status == 200) {
          if (avatarPush) {
            updateAvatar(avatarPush).then((result) => {
              if (result.status == 200) {
                setLoading(false);
                setAlertSuccess(true);
                setAlertDanger(false);
              } else {
                setLoading(false);
                setAlertDanger("updateAvatar " + JSON.stringify(result));
                setAlertSuccess(false);
              }
            });
          } else {
            setLoading(false);
            setAlertSuccess(true);
            setAlertDanger(false);
          }
          if (conditionUpdate === 1) {
            openModal();
          }
        } else {
          setLoading(false);
          setAlertDanger("updateProfile " + JSON.stringify(res));
          setAlertSuccess(false);
        }
      });
    }
  };

  const handleSignOut = () => {
    navigate('/signout');
  }

  return (
    <React.Fragment>
      <Section />

      <Container>
        <Col className="justify-content-center">
          <Card className="profile-content-page mt-4 mt-lg-0">
            {loading && <Spinners />}
            <Nav
              className="profile-content-nav nav-pills border-bottom mb-4"
              id="pills-tab"
              role="tablist"
            >
              <NavItem role="presentation">
                <NavLink
                  to="#"
                  className={classnames({ active: activeTab === "1" })}
                  onClick={() => {
                    tabChange("1");
                  }}
                  type="button"
                >
                  {t("account.taikhoan")}
                </NavLink>
              </NavItem>
            </Nav>

            <CardBody className="p-4">
              <TabContent activeTab={activeTab}>
                <TabPane tabId="1">
                  <div>
                    <h5 className="fs-17 fw-semibold mb-3 mb-0">{t("account.thongtintaikhoan")}</h5>
                    <div className="text-center">
                      <div className="mb-4 profile-user">
                        <img
                          style={{ backgroundColor: background_color }}
                          src={avatar ? avatar : userImage2}
                          className="rounded-circle img-thumbnail profile-img"
                          id="profile-img"
                          alt=""
                        />
                        <div className="p-0 rounded-circle profile-photo-edit">
                          <Input
                            id="profile-img-file-input"
                            type="file"
                            className="profile-img-file-input"
                            onChange={handleUploadAvatar}
                          />
                          <Label
                            htmlFor="profile-img-file-input"
                            className="profile-photo-edit avatar-xs"
                          >
                            <i className="uil uil-edit"></i>
                          </Label>
                        </div>
                      </div>
                    </div>
                    <div className="text-center">
                      {avatarErr && <label style={{ color: 'red', fontSize: 14 }}>{avatarErr}</label>}
                    </div>
                    <Row>
                      <Col lg={4}>
                        <div className="mb-3">
                          <label htmlFor="firstName" className="form-label">
                            {t("account.hoten")}
                          </label>
                          <label style={{ paddingLeft: 2, color: 'red', fontSize: 14 }}>*</label>
                          <div className="position-relative">
                            <Input
                              style={{ backgroundColor: background_color, paddingLeft: 40 }}
                              type="text"
                              className="form-control"
                              id="firstName"
                              onChange={handleFullName}
                              value={fullname}
                            />
                            <Icon style={{
                              position: 'absolute', top: '50%', left: '10px', transform: 'translateY(-50%)', color: 'green',
                              fontSize: '22px'
                            }} icon="mdi:user-outline" />
                          </div>
                          {fullnameErr && <label style={{ color: 'red', fontSize: 14 }}>{fullnameErr}</label>}
                        </div>
                      </Col>
                      <Col lg={4}>
                        <div className="mb-3">
                          <label htmlFor="otherName" className="form-label">
                            {t("account.tennuocngoai")}
                          </label>
                          <div className="position-relative">
                            <Input
                              style={{ backgroundColor: background_color, paddingLeft: 40 }}
                              type="text"
                              className="form-control"
                              id="otherName"
                              onChange={handleOtherName}
                              value={otherName}
                            />
                            <Icon style={{
                              position: 'absolute', top: '50%', left: '10px', transform: 'translateY(-50%)', color: 'green',
                              fontSize: '22px'
                            }} icon="mdi:user-outline" />
                          </div>
                        </div>
                      </Col>
                      <Col lg={3}>
                        <Row style={{ width: '100%' }}>
                          <label htmlFor="phone" className="form-label">
                            {t("account.sodienthoai")}
                          </label>
                          <div style={{ width: '30%' }}>
                            <Dropdown
                              isOpen={dropdownOpen}
                              toggle={toggle}
                            >
                              <DropdownToggle type="button" id="pagesdoropdown"
                                className="nav-link dropdown-toggle arrow-none"
                                style={{ width: '110%', height: '42.6px', borderColor: '#eff0f2', backgroundColor: background_color }}
                              >
                                <img src={flag} alt="" height="16" />
                                <div className="arrow-down"></div>
                              </DropdownToggle>

                              <DropdownMenu className="dropdown-menu-end" end>
                                <DropdownItem
                                  className="dropdown-item notify-item language"
                                  onClick={() => handleCodeArea('+84', 'vi')}
                                >
                                  <img src={flagVn} alt="" className="me-1" height="12" />
                                  <span style={{ fontWeight: 'normal', textTransform: 'none' }}>Vietnam +84</span>
                                </DropdownItem>
                                <DropdownItem
                                  className="dropdown-item notify-item language"
                                  onClick={() => handleCodeArea('+81', 'jp')}
                                >
                                  <img src={flagJp} alt="" className="me-1" height="12" />
                                  <span style={{ fontWeight: 'normal', textTransform: 'none' }}>Japan +81</span>
                                </DropdownItem>
                                <DropdownItem
                                  className="dropdown-item notify-item language"
                                  onClick={() => handleCodeArea('+44', 'uk')}
                                >
                                  <img src={flagUk} alt="" className="me-1" height="12" />
                                  <span style={{ fontWeight: 'normal', textTransform: 'none' }}>United Kingdom +44</span>
                                </DropdownItem>
                                <DropdownItem
                                  className="dropdown-item notify-item language"
                                  onClick={() => handleCodeArea('+1', 'us')}
                                >
                                  <img src={flagUs} alt="" className="me-1" height="12" />
                                  <span style={{ fontWeight: 'normal', textTransform: 'none' }}>United States +1</span>
                                </DropdownItem>
                                <DropdownItem
                                  className="dropdown-item notify-item language"
                                  onClick={() => handleCodeArea('+49', 'ge')}
                                >
                                  <img src={flagGr} alt="" className="me-1" height="12" />
                                  <span style={{ fontWeight: 'normal', textTransform: 'none' }}>Germany +49</span>
                                </DropdownItem>
                              </DropdownMenu>
                            </Dropdown>
                          </div>
                          <div style={{ width: '70%', marginLeft: -19 }}>
                            <div className="position-relative">
                              <Input
                                style={{ backgroundColor: background_color, paddingLeft: 40, width: '130%' }}
                                type="text"
                                className="form-control"
                                id="phone"
                                onChange={handlePhone}
                                value={phone}
                              />
                              <Icon style={{
                                position: 'absolute', top: '50%', left: '10px', transform: 'translateY(-50%)', color: 'green',
                                fontSize: '22px'
                              }} icon="ic:outline-phone" />
                            </div>
                          </div>
                          {phoneErr && <label style={{ color: 'red', fontSize: 14 }}>{phoneErr}</label>}
                        </Row>
                      </Col>
                      <Col lg={3}>
                        <div className="mb-3">
                          <label
                            htmlFor="choices-single-nationality"
                            className="form-label"
                          >
                            {t("account.quoctich")}
                          </label>
                          <div className="position-relative">
                            <Select
                              isMulti={false}
                              value={nationality}
                              placeholder={'Quốc tịch...'}
                              onChange={handleNationality}
                              styles={{
                                control: (baseStyles) => ({
                                  ...baseStyles,
                                  backgroundColor: background_color,
                                  paddingLeft: 40,
                                  borderColor: '#eff0f2',
                                  height: '42.6px'
                                }),
                              }}
                              options={countryList}
                              className="react-select-container"
                              classNamePrefix="react-select"
                            />
                            <Icon style={{
                              position: 'absolute', top: '50%', left: '10px', transform: 'translateY(-50%)', color: 'green',
                              fontSize: '22px'
                            }} icon="gis:search-country" />
                          </div>
                        </div>
                      </Col>
                      <Col lg={3}>
                        <div className="mb-3">
                          <Label htmlFor="facebook" className="form-label">
                            Facebook
                          </Label>
                          <div className="position-relative">
                            <Input
                              style={{ backgroundColor: background_color, paddingLeft: 40 }}
                              type="text"
                              className="form-control"
                              id="facebook"
                              onChange={handleFacebook}
                              value={facebook}
                            />
                            <Icon style={{
                              position: 'absolute', top: '50%', left: '10px', transform: 'translateY(-50%)', color: 'green',
                              fontSize: '22px'
                            }} icon="mdi:facebook" />
                          </div>
                        </div>
                      </Col>
                      <Col lg={3}>
                        <div className="mb-3">
                          <Label htmlFor="instagram" className="form-label">
                            Instagram
                          </Label>
                          <div className="position-relative">
                            <Input
                              style={{ backgroundColor: background_color, paddingLeft: 40 }}
                              type="text"
                              className="form-control"
                              id="instagram"
                              onChange={handleInstagram}
                              value={instagram}
                            />
                            <Icon style={{
                              position: 'absolute', top: '50%', left: '10px', transform: 'translateY(-50%)', color: 'green',
                              fontSize: '22px'
                            }} icon="mdi:instagram" />
                          </div>
                        </div>
                      </Col>
                      <Col lg={3}>
                        <div className="mb-3">
                          <Label htmlFor="twitter" className="form-label">
                            Twitter
                          </Label>
                          <div className="position-relative">
                            <Input
                              style={{ backgroundColor: background_color, paddingLeft: 40 }}
                              type="text"
                              className="form-control"
                              id="twitter"
                              onChange={handleTwitter}
                              value={twitter}
                            />
                            <Icon style={{
                              position: 'absolute', top: '50%', left: '10px', transform: 'translateY(-50%)', color: 'green',
                              fontSize: '22px'
                            }} icon="mdi:twitter" />
                          </div>
                        </div>
                      </Col>
                      <Col lg={3}>
                        <div className="mb-3">
                          <Label htmlFor="whatsapp" className="form-label">
                            WhatsApp
                          </Label>
                          <div className="position-relative">
                            <Input
                              style={{ backgroundColor: background_color, paddingLeft: 40 }}
                              type="text"
                              className="form-control"
                              id="whatsapp"
                              onChange={handleWhatsApp}
                              value={whatsapp}
                            />
                            <Icon style={{
                              position: 'absolute', top: '50%', left: '10px', transform: 'translateY(-50%)', color: 'green',
                              fontSize: '22px'
                            }} icon="mdi:whatsapp" />
                          </div>
                        </div>
                      </Col>
                    </Row>
                    <div className="mt-4">
                      <h5 className="fs-17 fw-semibold mb-3">{t("account.thongtincoban")}</h5>
                      <Row>
                        {gender && <Col lg={3}>
                          <div className="mb-3">
                            <Label htmlFor="languages" className="form-label">
                              {t("account.gioitinh")}
                            </Label>
                            <div className="position-relative">
                              <Select
                                isMulti={false}
                                options={genderList}
                                value={gender}
                                onChange={handleGender}
                                styles={{
                                  control: (baseStyles) => ({
                                    ...baseStyles,
                                    backgroundColor: background_color,
                                    borderColor: '#eff0f2',
                                    height: '42.6px',
                                    fontWeight: 500,
                                    paddingLeft: 40
                                  }),
                                }}
                                className="react-select-container"
                                classNamePrefix="react-select"
                              />
                              <Icon style={{
                                position: 'absolute', top: '50%', left: '10px', transform: 'translateY(-50%)', color: 'green',
                                fontSize: '22px'
                              }} icon="mdi:gender-male-female" />
                            </div>
                          </div>
                        </Col>}
                        {marriage && <Col lg={3}>
                          <div className="mb-3">
                            <Label htmlFor="marriage" className="form-label">
                              {t("account.kethon")}
                            </Label>
                            <div className="position-relative">
                              <Select
                                isMulti={false}
                                options={marriageList}
                                value={marriage}
                                onChange={handleMarriage}
                                styles={{
                                  control: (baseStyles) => ({
                                    ...baseStyles,
                                    backgroundColor: background_color,
                                    borderColor: '#eff0f2',
                                    height: '42.6px',
                                    fontWeight: 500,
                                    paddingLeft: 40
                                  }),
                                }}
                                className="react-select-container"
                                classNamePrefix="react-select"
                              />
                              <Icon style={{
                                position: 'absolute', top: '50%', left: '10px', transform: 'translateY(-50%)', color: 'green',
                                fontSize: '22px'
                              }} icon="mdi:account-heart-outline" />
                            </div>
                          </div>
                        </Col>}
                        <Col lg={3}>
                          <div className="mb-3">
                            <Label
                              htmlFor="exampleFormControlTextarea1"
                              className="form-label"
                            >
                              {t("account.ngaythangnamsinh")}
                            </Label>
                            <div className="position-relative">
                              <Input
                                style={{ backgroundColor: background_color, paddingLeft: 40 }}
                                type="date"
                                className="form-control"
                                id="birthday"
                                onChange={handleBirthDay}
                                value={birthDay}
                              />
                              <Icon
                                style={{
                                  position: 'absolute', top: '50%', left: '10px', transform: 'translateY(-50%)', color: 'green',
                                  fontSize: '20px'
                                }}
                                icon="ph:cake" />
                            </div>
                            {birthDayErr && <label style={{ color: 'red', fontSize: 14 }}>{birthDayErr}</label>}
                          </div>
                        </Col>
                        <Col lg={3}>
                          <div className="mb-3">
                            <Label
                              htmlFor="height"
                              className="form-label"
                            >
                              {t("account.tuoi")}
                            </Label>
                            <Input
                              style={{ backgroundColor: background_color }}
                              type="number"
                              className="form-control"
                              id="age"
                              onChange={handleAge}
                              value={age}
                              min='0' max='120'
                            />
                          </div>
                        </Col>
                        <Col lg={9}>
                          <Row style={{ width: '100%' }}>
                            <Label
                              htmlFor="industry"
                              className="form-label"
                            >
                              {t("account.diachihientai")}
                            </Label>
                            <div style={{ width: '20%' }}>
                              <Select
                                isMulti={false}
                                value={countryId}
                                placeholder={t("companydetail.quocgia")}
                                onChange={handleCountryId}
                                styles={{
                                  control: (baseStyles) => ({
                                    ...baseStyles,
                                    backgroundColor: background_color,
                                    borderColor: '#eff0f2',
                                    height: '42.6px',
                                    fontWeight: 500
                                  }),
                                }}
                                options={countryList}
                                className="react-select-container"
                                classNamePrefix="react-select"
                              />
                            </div>
                            <div style={{ width: '30%', marginLeft: -23 }}>
                              <Select
                                className="react-select-container"
                                classNamePrefix="react-select"
                                isMulti={false}
                                value={cityId}
                                placeholder={t("companydetail.thanhpho")}
                                onChange={handleCity}
                                styles={{
                                  control: (baseStyles) => ({
                                    ...baseStyles,
                                    backgroundColor: background_color,
                                    borderColor: '#eff0f2',
                                    height: '42.6px',
                                    fontWeight: 500
                                  }),
                                }}
                                options={cityList}
                              />
                            </div>
                            <Input
                              style={{ backgroundColor: background_color, width: '50%', marginLeft: -11 }}
                              type="text"
                              className="form-control"
                              id="address"
                              onChange={handleAddress}
                              value={address}
                            />
                          </Row>
                        </Col>
                        <Col lg={3}>
                          <div className="mb-3">
                            <Label htmlFor="permanentAddress" className="form-label">
                              {t("account.quequan")}
                            </Label>
                            <div className="position-relative">
                              <Input
                                style={{ backgroundColor: background_color, paddingLeft: 40 }}
                                type="text"
                                className="form-control"
                                id="permanentAddress"
                                onChange={handlePermanentAddress}
                                value={permanentAddress}
                              />
                              <Icon
                                style={{
                                  position: 'absolute', top: '50%', left: '10px', transform: 'translateY(-50%)', color: 'green',
                                  fontSize: '20px'
                                }}
                                icon="ion:location-outline" />
                            </div>
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </div>
                  <div className="mt-4">
                    <h5 className="fs-17 fw-semibold mb-3">{t("account.matkhau")}</h5>
                    <Row>
                      <Col lg={7}>
                        <div className="mb-3">
                          <label className="form-label" htmlFor="oldPassword">
                            {t("account.matkhaucu")}
                          </label>
                          <Input
                            style={{ backgroundColor: background_color }}
                            type={oldPasswordType}
                            className="form-control"
                            id="oldPasswordInput"
                            placeholder={t("account.nhaplaimatkhaucu")}
                            onChange={handleOldPassword}
                            value={oldPassword}
                          />
                          <p style={{ float: "right", position: "relative", marginTop: -33, marginRight: 10 }} onClick={handleShowOldPassword}>
                            {oldPasswordType === "password" ? <i className="mdi mdi-eye-off"></i> : <i className="mdi mdi-eye"></i>}
                          </p>
                          <label style={{ color: 'red', fontSize: 14 }}>{oldPasswordErr}</label>
                        </div>
                      </Col>
                      <Col lg={7}>
                        <div className="mb-3">
                          <label className="form-label" htmlFor="newPassword">
                            {t("account.matkhaumoi")}
                          </label>
                          <Input
                            style={{ backgroundColor: background_color }}
                            type={newPasswordType}
                            className="form-control"
                            id="newPasswordInput"
                            placeholder={t("doimatkhau.nhapmatkhaumoi")}
                            onChange={handleNewPassword}
                            value={newPassword}
                          />
                          <p style={{ float: "right", position: "relative", marginTop: -33, marginRight: 10 }} onClick={handleShowNewPassword}>
                            {newPasswordType === "password" ? <i className="mdi mdi-eye-off"></i> : <i className="mdi mdi-eye"></i>}
                          </p>
                          <label style={{ color: 'red', fontSize: 14 }}>{newPasswordErr}</label>
                        </div>
                      </Col>
                      <Col lg={7}>
                        <div className="mb-3">
                          <label className="form-label" htmlFor="confirmNewPassword">
                            {t("account.nhaplaimatkhaumoi")}
                          </label>
                          <Input
                            style={{ backgroundColor: background_color }}
                            type={confirmNewPasswordType}
                            className="form-control"
                            id="confirmNewPasswordInput"
                            placeholder={t("account.nhaplaimatkhaumoi")}
                            onChange={handleConfirmNewPassword}
                            value={confirmNewPassword}
                          />
                          <p style={{ float: "right", position: "relative", marginTop: -33, marginRight: 10 }} onClick={handleShowConfirmNewPassword}>
                            {confirmNewPasswordType === "password" ? <i className="mdi mdi-eye-off"></i> : <i className="mdi mdi-eye"></i>}
                          </p>
                          <label style={{ color: 'red', fontSize: 14 }}>{confirmNewPasswordErr}</label>
                        </div>
                      </Col>
                    </Row>
                  </div>
                  <div className="mt-4 text-end">
                    <button
                      className="btn btn-primary"
                      onClick={handleUpdateProfile}
                      disabled={invalid}
                    >
                      <i className="uil uil-save"></i> {t("account.capnhat")}
                    </button>
                  </div>
                </TabPane>
              </TabContent>
            </CardBody>
          </Card>
          {alertsuccess && <AlertsSuccess message={MESSAGE_VALIDATE.update_profile_success} />}
          {alertdanger && <Alerts message={alertdanger} />}
          {alertvalidate && <Alerts message={MESSAGE_VALIDATE.update_profile_validate} />}
        </Col>
      </Container>

      <div className="modal-dialog modal-dialog-centered">
        <Modal isOpen={modal} toggle={openModal} size="lg" centered backdrop="static">
          <div className="modal-header">
            <h5 className="modal-title mt-0" id="myModalLabel">
              Cập nhật tài khoản
            </h5>
          </div>
          <ModalBody className="modal-body p-5">
            <div className="text-center mb-4">
              <h5>
                Bạn vừa thay đổi mật khẩu, vui lòng đăng nhập lại!
              </h5>
            </div>
          </ModalBody>
          <ModalFooter>
            <button
              type="button"
              className="btn btn-primary waves-effect waves-light"
              onClick={handleSignOut}
            >
              OK
            </button>
          </ModalFooter>
        </Modal>
      </div>
    </React.Fragment >
  );
};

export default Account;