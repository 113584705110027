import { useEffect } from "react";
import "./GTranslate.css"

export const GTranslate = () => {

    useEffect(() => {
        window.gtranslateSettings = {
            default_language: "vi",
            languages: ["vi", "ja", "en", "ko", "de"],
            wrapper_selector: ".gtranslate_wrapper",
            switcher_horizontal_position: "right",
            switcher_vertical_position: "top",
            float_switcher_open_direction: "bottom"
        };

        const script = document.createElement("script");
        script.src = "https://cdn.gtranslate.net/widgets/latest/float.js";
        document.body.appendChild(script);
        document.documentElement.lang = "auto";
    }, []);

    return (
        <>
            <div className="gtranslate_wrapper"></div>
        </>
    );
};