import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Container } from "reactstrap";
import PageHeader from "../../Component/PageHeader";
import ManageJobApplyList from "./ManageJobApplyList.js";
import { MasterContext } from "../../../context/MasterContext.js";


const ManageJobApply = () => {
    const { t } = useTranslation("translation");
    const masterData = React.useContext(MasterContext);
    document.title = t("managejob.danhsachungtuyen");

    let [countryListAll, setCountryListAll] = useState();

    useEffect(() => {
        setCountryListAll(masterData);
    }, [])


    return (
        <React.Fragment>
            <PageHeader title={t("managejob.danhsachungtuyen")} />
            <section className="section">
                <Container>
                    {countryListAll && <ManageJobApplyList countryData={countryListAll} />}
                </Container>
            </section>
        </React.Fragment>
    )
};

export default ManageJobApply;