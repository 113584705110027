import React from "react";
import { Container } from "reactstrap";
import PageHeader from "../Component/PageHeader";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import { Row, Col, Form, Label, CardBody, Card } from "reactstrap";
import Select from "react-select";
import UserListLoading from "../../common/placeholder/UserListLoading";
import { getAllEmployee } from "../../api/apiAccount";
import userDefaultImage from "../../assets/images/featured-job/img-default.webp";
import { checkIconCountry } from "../../common/checkValidate/checkIconCountry";
import Pagination from "../Jobs/Pagination/Pagination";
import ModalUserInfo from "../../common/modal/ModalUserInfo";
import { Link } from "react-router-dom";
import { MasterContext } from "../../context/MasterContext";
import { getStorage } from "../../common/constants/Services";
import { STORAGE_KEYS } from "../../common/constants/Storage";

const Employees = (props) => {
  const { t } = useTranslation("translation");

  const title = t("home.timkiemungvien");
  document.title = title;

  const experienceRequiredList = [
    { value: "0", label: t("kinhnghiem.khongyeucau") },
    { value: "1", label: t("kinhnghiem.1nam") },
    { value: "2", label: t("kinhnghiem.2nam") },
    { value: "3", label: t("kinhnghiem.3nam") },
    { value: "4", label: t("kinhnghiem.4nam") },
    { value: "5", label: t("kinhnghiem.5nam") },
  ];

  let [masterList, setMasterList] = useState([]);
  let [searchCategory, setSearchCategory] = useState({ value: 0, label: t("managejob.tatca") });
  let [searchCountry, setSearchCountry] = useState({ value: 0, label: t("managejob.tatca") });
  let [searchExperience, setSearchExperience] = useState([]);
  let [searchWorkcountry, setSearchWorkcountry] = useState({ value: 0, label: t("managejob.tatca") });

  let [countryList, setCountryList] = useState([{ value: 0, label: t("managejob.tatca") }]);
  let [categoryList, setCategoryList] = useState([{ value: 0, label: t("managejob.tatca") }]);
  let [curentPage, setCurentPage] = useState(1);

  let [totalPage, setTotalPage] = useState(1);
  let [employees, setEmployees] = useState([]);
  let [loading, setLoading] = useState(true);

  let [username, setUsername] = useState('');
  const [modalViewProfile, setModalViewProfile] = useState(false);
  const masterData = React.useContext(MasterContext);

  useEffect(() => {
    if (masterData) {
      if (masterData.country) {
        const countryArr = [{ value: 0, label: t("managejob.tatca") }];
        setSearchCountry(countryArr[0]);
        setSearchWorkcountry(countryArr[0]);
        masterData.country.map((item, key) => {
          countryArr.push({ value: item.id, label: item.name });
        })
        setCountryList(countryArr);
        const catList = [{ value: 0, label: t("managejob.tatca") }];
        setSearchCategory(catList[0]);
        masterData.category.map((item, key) => {
          catList.push({ value: item.id, label: item.nameVi });
        })
        setCategoryList(catList);
        setMasterList(masterData);
        setSearchExperience({ value: 0, label: t("managejob.tatca") })
      }
    }

  }, [masterData]);

  useEffect(() => { LoadData(1); }, []);

  const LoadData = (page) => {
    setLoading(true);
    getAllEmployee(page, searchCountry.value, searchExperience.value, searchWorkcountry.value, searchCategory.value).then((result) => {
      setEmployees(result.data);
      setTotalPage(result.total);
      setCurentPage(page);
      setLoading(false);
    });
  }

  const openModalViewProfile = (user) => {
    setModalViewProfile(true);
    setUsername(user.userName);
  }

  const closeModalViewProfile = () => {
    setModalViewProfile(false);
  }

  const handleCountry = (e) => {
    setSearchCountry({ value: e.value, label: e.label });
  };
  const handleWorkCountry = (e) => {
    setSearchWorkcountry({ value: e.value, label: e.label });
  };
  const handleExperienceRequired = (e) => {
    setSearchExperience({ value: e.value, label: e.label });
  };
  const handleCategory = (e) => {
    setSearchCategory({ value: e.value, label: e.label });
  };
  const handleFilter = () => {
    LoadData(1);
  };

  const updateCurrentPage = (page) => {
    setCurentPage(page);
    LoadData(page);
  }

  const getCountryByCity = (cityId) => {
    if (!cityId) return "";
    let ret = "";
    if (masterList.country instanceof Array) {
      masterList.country.forEach(element => {
        element.cities.forEach(city => {
          if (city.id === cityId) {
            ret = element.name;
          }
        });
      });
    }
    return ret;
  };
  return (
    <React.Fragment>
      <PageHeader title={title} />
      <section className="section">
        <Container>
          <Row className="align-items-center mb-4">
            <div className="me-lg-5">
              <div className="job-list-header">
                <Form action="#">
                  <Row className="g-2">
                    <Col lg={3} md={6}>
                      <label className="form-label">{t("Quốc tịch")}</label>
                      <div className="filler-job-form">
                        <Select
                          options={countryList}
                          className="react-select-container "
                          value={searchCountry}
                          onChange={handleCountry}
                          styles={{
                            control: (baseStyles) => ({
                              ...baseStyles,
                              borderColor: '#eff0f2',
                              height: '42.6px',
                              fontWeight: 500,
                              paddingLeft: 0
                            }),
                          }}
                          classNamePrefix="react-select"
                        />
                      </div>
                    </Col>
                    <Col lg={3} md={6}>
                      <Label htmlFor="experienceRequired" className="form-label">
                        {t("jobdetail.kinhnghiem")}
                      </Label>
                      <div className="filler-job-form">

                        <Select
                          options={experienceRequiredList}
                          className="react-select-container "
                          value={searchExperience}
                          onChange={handleExperienceRequired}
                          styles={{
                            control: (baseStyles) => ({
                              ...baseStyles,
                              borderColor: '#eff0f2',
                              height: '42.6px',
                              fontWeight: 500,
                              paddingLeft: 0
                            }),
                          }}
                          classNamePrefix="react-select"
                        />
                      </div>
                    </Col>
                    <Col lg={3} md={6}>
                      <label className="form-label">{t("managejob.noilamviec")}</label>
                      <div className="filler-job-form">
                        <Select
                          options={countryList}
                          className="react-select-container "
                          value={searchWorkcountry}
                          onChange={handleWorkCountry}
                          styles={{
                            control: (baseStyles) => ({
                              ...baseStyles,
                              borderColor: '#eff0f2',
                              height: '42.6px',
                              fontWeight: 500,
                              paddingLeft: 0
                            }),
                          }}
                          classNamePrefix="react-select"

                        />
                      </div>
                    </Col>
                    <Col lg={3} md={6}>
                      <label className="form-label">{t("managejob.nganhnghe")}</label>
                      <div className="filler-job-form">
                        <Select
                          options={categoryList}
                          className="react-select-container "
                          value={searchCategory}
                          onChange={handleCategory}
                          styles={{
                            control: (baseStyles) => ({
                              ...baseStyles,
                              borderColor: '#eff0f2',
                              height: '42.6px',
                              fontWeight: 500,
                              paddingLeft: 0
                            }),
                          }}
                          classNamePrefix="react-select"

                        />
                      </div>
                    </Col>

                  </Row>
                  <Row className="g-2">
                    <Col lg={12} className="mt-3 text-end">
                      <button
                        type="button"
                        className="btn btn-primary btn-lg"
                        onClick={handleFilter}
                      >
                        {t("managejob.timkiem")}
                      </button>
                    </Col>
                  </Row>
                </Form>
              </div>
            </div>
          </Row>
          <Row>
            {loading && !employees.length ? (<UserListLoading />) : employees.map((emp, key) => (
              <Col lg={2} md={3} xs={6} key={key}>
                <Card className="team-box card border-0 mt-4">
                  <div className="team-img position-relative mx-auto">
                    <Link onClick={() => openModalViewProfile(emp)} className="primary-link">
                      <img src={emp.avatar ? emp.avatar : userDefaultImage} style={{ height: '150px', width: '150px' }} className="img-thumbnail" alt="Landrick" />
                    </Link>
                  </div>
                  <CardBody className="team-content card-body text-center">
                    <Link onClick={() => openModalViewProfile(emp)} className="primary-link"><h6 className="fs-17 mb-1">{emp.fullName}</h6></Link>
                    {
                      emp.cityId &&
                      <div className="d-flex mb-2">
                        <div className="flex-shrink-0">
                          <i className="mdi mdi-map-marker text-primary me-1"></i>
                        </div>
                        <div style={{ display: "flex", flexDirection: "row" }}>
                          <img src={checkIconCountry(masterList.country, emp.cityId)} style={{ marginTop: 3 }} height="18" />
                          <p className="job-homepage text-muted mb-0" style={{ paddingLeft: 5 }}>{getCountryByCity(emp.cityId)}</p>
                        </div>
                      </div>
                    }
                  </CardBody>
                </Card>

              </Col>
            ))}
          </Row>
          <Row>
            <Pagination totalPage={totalPage} currentPage={updateCurrentPage} />
          </Row>
        </Container>
        {modalViewProfile && <ModalUserInfo username={username} countryData={masterList} modalState={modalViewProfile} toggleModal={closeModalViewProfile} />}
      </section>
    </React.Fragment>
  );
};

export default Employees;
