import React, { useEffect } from "react";
import Routes from "./Routes/index";
import "../src/common/i18n/i18n";
import { useState } from 'react';
//import Custom Style scss
import "./assets/scss/themes.scss";
import { GetCountryList } from "./api/apiCountry";
import { MasterContext } from "./context/MasterContext";
import { GTranslate } from "./common/i18n/GTranslate";

function App(props) {
  let [masterData, setMasterData] = useState([]);
  let [loading, setLoading] = useState(true);
  useEffect(() => {
    GetCountryList().then((result) => {
      if (result) {
        setMasterData(result);
      }
    });
    setLoading(false);
  }, [props]);

  return (
    <React.Fragment>
      {loading ? (
        <div id="preloader">
          <div id="status">
            <ul>
              <li></li>
              <li></li>
              <li></li>
              <li></li>
              <li></li>
              <li></li>
            </ul>
          </div>
        </div>
      ) : (
        <MasterContext.Provider value={masterData}>
          <GTranslate />
          <Routes />
        </MasterContext.Provider>
      )}
    </React.Fragment>
  );
}

export default App;
