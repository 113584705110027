import Japanese from "../i18n/jp.json";
import { eyesightList } from "../dropdownList/dropdownData";
import { UserSkill, UserEducation } from "./SkillJP";

export const japanCV = (gender, marriage, bloodType, handedness, eyesight, qualifications, japaneseLevel, englishLevel,
    favoriteSubjects, personality, strongPoints, weakPoints, visaHistories, certificateOfEligibility, avatar, address, fullname, otherName,
    yourself, email, phone, birthDay, age, height, weight, otherLevel, certificateJapaneseLevelList, certificateEnglishLevelList, masterData, userSkillData, userEducationData, userWorkExps
) => {
    const userSkills = UserSkill(masterData, userSkillData);
    const userEducations = UserEducation(userEducationData);
    const genderList = [
        { value: "0", label: Japanese["gioitinh.khonghienthi"] },
        { value: "1", label: Japanese["gioitinh.nam"] },
        { value: "2", label: Japanese["gioitinh.nu"] },
        { value: "3", label: Japanese["gioitinh.namnu"] },
    ];

    const bloodTypeList = [
        { value: "1", label: Japanese["createcv.khongbiet"] },
        { value: "2", label: "A" },
        { value: "3", label: "B" },
        { value: "4", label: "AB" },
        { value: "5", label: "O" },
    ];

    const marriageList = [
        { value: "1", label: Japanese["kethon.docthan"] },
        { value: "2", label: Japanese["kethon.dakethon"] },
        { value: "3", label: Japanese["kethon.dalyhon"] },
    ];

    const preferredHandList = [
        { value: "1", label: Japanese["taythuan.trai"] },
        { value: "2", label: Japanese["taythuan.phai"] },
    ];

    const qualificationList = [
        { value: "0", label: Japanese["kinhnghiem.khongyeucau"] },
        { value: "1", label: Japanese["bangcap.laixesosan"] },
        { value: "2", label: Japanese["bangcap.laixesotudong"] },
        { value: "3", label: Japanese["bangcap.xemay"] },
        { value: "4", label: Japanese["bangcap.xaydung"] },
        { value: "5", label: Japanese["bangcap.xenang"] },
        { value: "6", label: Japanese["bangcap.may"] },
        { value: "7", label: Japanese["bangcap.lamvuon"] },
    ];

    const certificateOfEligibilityList = [
        { value: "1", label: Japanese["visa.chuatung"] },
        { value: "2", label: Japanese["visa.datung"] },
    ];

    const favoriteSubjectList = [
        { value: "1", label: Japanese["monhoc.toan"] },
        { value: "2", label: Japanese["monhoc.van"] },
        { value: "3", label: Japanese["monhoc.ly"] },
        { value: "4", label: Japanese["monhoc.hoa"] },
        { value: "5", label: Japanese["monhoc.ngoaingu"] },
        { value: "6", label: Japanese["monhoc.dia"] },
        { value: "7", label: Japanese["monhoc.su"] },
        { value: "8", label: Japanese["monhoc.theduc"] },
    ];

    const strongPointList = [
        { value: "1", label: Japanese["sotruong.chudong"] },
        { value: "2", label: Japanese["sotruong.lacquan"] },
        { value: "3", label: Japanese["sotruong.hoatbat"] },
        { value: "4", label: Japanese["sotruong.haihuoc"] },
        { value: "5", label: Japanese["sotruong.thantrong"] },
    ];

    const weakpointList = [
        { value: "1", label: Japanese["sodoan.biquan"] },
        { value: "2", label: Japanese["sodoan.thieukiennhan"] },
        { value: "3", label: Japanese["sodoan.ngaingung"] },
        { value: "4", label: Japanese["sodoan.haysuynghi"] },
        { value: "5", label: Japanese["sodoan.nhutnhat"] },
        { value: "6", label: Japanese["sodoan.tomo"] },
    ];

    const visaTypeList = [
        { value: "1", label: Japanese["visa.dulich"] },
        { value: "2", label: Japanese["visa.thamthan"] },
        { value: "3", label: Japanese["visa.thuongmai"] },
        { value: "4", label: Japanese["visa.quacanh"] },
        { value: "5", label: Japanese["visa.duhoc"] },
        { value: "6", label: Japanese["visa.laodong"] },
    ];

    const personalityList = [
        { value: "1", label: Japanese["tinhcach.thattha"] },
        { value: "2", label: Japanese["tinhcach.nhiettinh"] },
        { value: "3", label: Japanese["tinhcach.noluc"] },
        { value: "4", label: Japanese["tinhcach.quantam"] },
        { value: "5", label: Japanese["tinhcach.nghiemtuc"] },
        { value: "6", label: Japanese["tinhcach.manhme"] },
        { value: "7", label: Japanese["tinhcach.mongmanh"] },
        { value: "8", label: Japanese["tinhcach.noinhieu"] },
        { value: "9", label: Japanese["tinhcach.hienlanh"] },
        { value: "10", label: "保守的" },
    ];

    let genderPdf = genderList.filter(g => g.value == gender);
    let marriagePdf = marriageList.filter(m => m.value == marriage);
    let bloodPdf = bloodTypeList.filter(b => b.value == bloodType);
    let handPdf = preferredHandList.filter(p => p.value == handedness);
    let eyesightPdf = eyesightList.filter(e => e.value == eyesight);
    let qualificationPdf = qualificationList.filter(q => q.value == qualifications);
    let japanesePdf = certificateJapaneseLevelList.filter(c => c.value == japaneseLevel);
    let englishPdf = certificateEnglishLevelList.filter(c => c.value == englishLevel);
    let favoriteSubjectPdf = favoriteSubjectList.filter(f => f.value == favoriteSubjects);
    let personalityPdf = personalityList.filter(p => p.value == personality);
    let strongPointPdf = strongPointList.filter(s => s.value == strongPoints);
    let weakPointPdf = weakpointList.filter(w => w.value == weakPoints);
    let visaPdf = [];
    if (visaHistories.length > 0 && certificateOfEligibility.value == 2) {
        visaHistories.map((item, key) => {
            let visaTypePdf = visaTypeList.filter(v => v.value == item.visaId.value);
            visaPdf.push({ visaFromDate: item.visaFromDate, visaExpDate: item.visaExpDate, visaType: visaTypePdf[0].label, country: item.country.label });

        })
    } else {
        visaPdf = certificateOfEligibilityList[0].label;
    }

    let data = {
        avatar: avatar,
        address: address,
        fullName: fullname,
        otherName: otherName,
        yourself: yourself,
        email: email,
        phone: phone,
        gender: genderPdf[0].label,
        marriage: marriagePdf[0].label,
        birthDay: birthDay,
        age: age,
        height: height + " cm",
        weight: weight + " kg",
        bloodType: bloodPdf[0].label,
        handedness: handPdf[0].label,
        eyesight: eyesightPdf[0].label,
        qualifications: qualificationPdf[0].label,
        japaneseLevel: japaneseLevel == 0 ? "なし" : japanesePdf[0].label,
        englishLevel: englishPdf[0].label,
        otherLevel: otherLevel,
        certificateOfEligibility: certificateOfEligibility,
        visaHistories: visaPdf,
        userSkills: userSkills,
        userEducations: userEducations,
        userWorkExps: userWorkExps,
        favoriteSubjects: favoriteSubjectPdf[0].label,
        personality: personalityPdf[0].label,
        strongPoints: strongPointPdf[0].label,
        weakPoints: weakPointPdf[0].label,
    }

    return data;
}