import React, { useState, useEffect } from "react";
import { Col, Row, Container } from "reactstrap";
import { useTranslation } from "react-i18next";
import { getMyApplied } from "../../../api/apiJob";
import Section from "../../../pages/Component/PageHeader";
import { numberFormat } from "../../../common/checkValidate/formatMoney";
import PlaceholderJobFilter from "../../../common/placeholder/PlaceholderJobFilter";
import { MasterContext } from "../../../context/MasterContext";
import JobItemComponent from "../../../common/jobAllList/JobItemComponent";


const JobMyApplied = () => {

  const { t } = useTranslation("translation");
  const masterData = React.useContext(MasterContext);
  document.title = t("vieclamdangungtuyen");

  const experienceRequiredList = [
    { value: "0", label: t("kinhnghiem.khongyeucau") },
    { value: "1", label: t("kinhnghiem.1nam") },
    { value: "2", label: t("kinhnghiem.2nam") },
    { value: "3", label: t("kinhnghiem.3nam") },
    { value: "4", label: t("kinhnghiem.4nam") },
    { value: "5", label: t("kinhnghiem.5nam") },
  ];
  
  let [jobListing, setJobListing] = useState([]);
  let [alertNoData, setAlertNoData] = useState('');

  let [countryData, setCountryData] = useState([]);
  let [categoryData, setCategoryData] = useState([]);

  useEffect(() => {
    if (masterData.country) {
      setCountryData(masterData.country);
    }
    if (masterData.category) {
      setCategoryData(masterData.category);
    }
  }, []);

  useEffect(() => {
    getMyApplied().then((res) => {
      const job = [];
      if (res.length) {
        res.map((item, key) => {
          job.push({
            id: item.job.id,
            cityId: item.job.cityId,
            catId: item.job.catId,
            jobTitle: item.job.title,
            company: item.job.company,
            workLocationAddress: item.job.workLocationAddress,
            dateNeeded: item.job.dateNeeded,
            jobType: item.job.jobType,
            salary: numberFormat(item.job.salaryFrom) + ' - ' + numberFormat(item.job.salaryTo) + ' ' + item.job.currency,
            addclassNameBookmark: false,
            badges: [],
            experience: convertExperience(item.job.experienceRequired),
            featured: item.job.featured,
          });
        });
        setJobListing(job);
        job.length == 0 ? setAlertNoData('Không có việc làm đang ứng tuyển!') : setAlertNoData('');
      } else {
        setAlertNoData('Không có việc làm đang ứng tuyển!');
        setJobListing([]);
      }
    });
  }, []);

  const convertExperience = (experienceRequired) => {
    let experience = '';
    if (experienceRequired === 0) {
      experience = experienceRequiredList[0].label;
    } else if (experienceRequired === 1) {
      experience = experienceRequiredList[1].label;
    } else if (experienceRequired === 2) {
      experience = experienceRequiredList[2].label;
    } else if (experienceRequired === 3) {
      experience = experienceRequiredList[3].label;
    } else if (experienceRequired === 4) {
      experience = experienceRequiredList[4].label;
    } else {
      experience = experienceRequiredList[5].label;
    };
    return experience;
  }

  return (
    <React.Fragment>
      <Section title={t("vieclamdangungtuyen")} />
      <section className="section">
        <Container>
          <Row>
            <Col lg={12}>
              {alertNoData && <div className="section-title text-center">
                <h3 className="title">{alertNoData}</h3>
              </div>}
              {(!jobListing.length && !alertNoData) ? (<PlaceholderJobFilter />) :
                <Row className="align-items-center">
                  {jobListing && jobListing.map((jobDetail, key) => (
                    <Col lg={12} md={12} key={key}>
                      <JobItemComponent jobDetail={jobDetail} myApplied={true} countryData={countryData} categories={categoryData} />
                    </Col>
                  ))}
                </Row>
              }
            </Col>
          </Row>
        </Container>
      </section>

    </React.Fragment>
  );
};

export default JobMyApplied;
