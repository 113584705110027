import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Col, Input, Row, Form, Collapse, Button } from "reactstrap";
import { getListJobAll } from "../../../api/apiJob";
import Pagination from "../Pagination/Pagination";
import Select from 'react-select';
import CurrencyInput from 'react-currency-input-field';
import JobAllList from "../../../common/jobAllList/JobAllList";
import PlaceholderJobFilter from "../../../common/placeholder/PlaceholderJobFilter";
import { numberFormat } from "../../../common/checkValidate/formatMoney";
import { checkIconIndustry } from "../../../common/checkValidate/checkIconIndustry";
import { checkIconCountry } from "../../../common/checkValidate/checkIconCountry";
import { getJobIcon } from "../../../services/common";
import { useTranslation } from "react-i18next";
import { STORAGE_KEYS } from "../../../common/constants/Storage";
import { getStorage } from "../../../common/constants/Services";
import { MasterContext } from "../../../context/MasterContext";

const JobVacancyList = (props) => {
  const { t } = useTranslation("translation");

  const experienceRequiredList = [
    { value: "0", label: t("kinhnghiem.khongyeucau") },
    { value: "1", label: t("kinhnghiem.1nam") },
    { value: "2", label: t("kinhnghiem.2nam") },
    { value: "3", label: t("kinhnghiem.3nam") },
    { value: "4", label: t("kinhnghiem.4nam") },
    { value: "5", label: t("kinhnghiem.5nam") },
  ];

  const jobTypeList = [
    { value: "2", label: t("loaicongviec.tatca") },
    { value: "0", label: t("loaicongviec.toanthoigian") },
    { value: "1", label: t("loaicongviec.banthoigian") },
  ];

  const jobTypeListFilter = [
    { value: "-1", label: t("managejob.hinhthuclamviec") },
    { value: "-2", label: t("loaicongviec.tatca") },
    { value: "0", label: t("loaicongviec.toanthoigian") },
    { value: "1", label: t("loaicongviec.banthoigian") },
  ];

  const experienceRequiredListFilter = [
    { value: "-1", label: t("managejob.kinhnghiem") },
    { value: "0", label: t("kinhnghiem.khongyeucau") },
    { value: "1", label: t("kinhnghiem.1nam") },
    { value: "2", label: t("kinhnghiem.2nam") },
    { value: "3", label: t("kinhnghiem.3nam") },
    { value: "4", label: t("kinhnghiem.4nam") },
    { value: "5", label: t("kinhnghiem.5nam") },
  ];

  const colourStyles = {
    control: (base) => ({
      ...base,
      border: 0,
      boxShadow: "none",
      padding: "12px 0 12px 35px",
      margin: "-16px 0 0 -45px",
      borderRadius: "0",
      outline: "none",
    })
  };
  let [name, setName] = useState('');
  let [salaryTo, setSalaryTo] = useState('');
  let [countryid, setCountryId] = useState('');
  let [countryList, setCountryList] = useState();
  let [cityId, setCityId] = useState('');
  let [cityList, setCityList] = useState();
  let [cityListAll, setCityListAll] = useState();
  let [industry, setIndustry] = useState('');
  let [industryList, setIndustryList] = useState();
  let [jobVacancyList, setJobVacancyList] = useState([]);
  let [experience, setExperience] = useState('');
  let [jobType, setJobType] = useState('');
  let [countryData, setCountryData] = useState();

  let [alertNoData, setAlertNoData] = useState('');
  let [totalPage, setTotalPage] = useState(1);
  //Sidebar
  const [toggleFilter, setToggleFilter] = useState(false);
  const masterData = React.useContext(MasterContext);

  useEffect(() => {
    if (masterData) {
      if (masterData.country) {
        const countryArr = [{ value: -1, label: t("companydetail.quocgia") }, { value: 0, label: t("managejob.tatca") }];
        const cityArr = [{ value: -1, label: t("companydetail.thanhpho") }];
        const cityListAllArr = [];
        setCountryData(masterData.country);
        masterData.country.map((item, key) => {
          if (props.data.countryid.value <= 0) {
            setCountryId(countryArr[0]);
            setCityId(cityArr[0]);
            setCityList(cityArr);
          }
          if (item.id == props.data.countryid.value) {
            setCountryId({ value: item.id, label: item.name });
            let cities = item.cities.filter(c => c.countryID === item.id);
            if (cities.length > 0) {
              cities.map((itemcity, keycity) => {
                if (keycity === 0) {
                  setCityId({ value: itemcity.id, label: itemcity.name });
                }
                cityArr.push({ value: itemcity.id, label: itemcity.name });
              })
              setCityList(cityArr);
            }
          }
          countryArr.push({ value: item.id, label: item.name });
          cityListAllArr.push(item.cities);
        })
        setCountryList(countryArr);
        setCityListAll(cityListAllArr);
      }
      if (masterData.category) {
        const industryArr = [{ value: -1, label: t('managejob.nganhnghe') }, { value: 0, label: t("managejob.tatca") }];
        masterData.category.map((item, key) => {
          if (props.data.industry.value <= 0) {
            setIndustry(industryArr[0]);
          }
          if (item.id == props.data.industry.value) {
            setIndustry({ value: item.id, label: item.nameVi });
          }
          industryArr.push({ value: item.id, label: item.nameVi });
        });
        setIndustryList(industryArr);
      }
    };
    setJobType({ value: '-1', label: t("managejob.hinhthuclamviec") });
    setExperience({ value: '-1', label: t("jobdetail.kinhnghiem") });
    setName(props.data.name);
  }, [])

  useEffect(() => {
    getListJobAllFilter(1, props.data.name, props.data.countryid.value, "", props.data.industry.value, "", -1, -1);
  }, [countryData])

  const convertExperience = (experienceRequired) => {
    let experience = '';
    if (experienceRequired === 0) {
      experience = experienceRequiredList[0].label;
    } else if (experienceRequired === 1) {
      experience = experienceRequiredList[1].label;
    } else if (experienceRequired === 2) {
      experience = experienceRequiredList[2].label;
    } else if (experienceRequired === 3) {
      experience = experienceRequiredList[3].label;
    } else if (experienceRequired === 4) {
      experience = experienceRequiredList[4].label;
    } else {
      experience = experienceRequiredList[5].label;
    };
    return experience;
  }

  const handleName = event => {
    setName(event.target.value);
  }

  const handleCountry = event => {
    setCountryId(event);
    cityListAll.map((item, key) => {
      const citieDropdown = item.filter(c => c.countryID == event.value);
      if (citieDropdown.length > 0) {
        const cityListArr = [{ value: -1, label: t("companydetail.thanhpho") }];
        citieDropdown.map((itemcity, keycity) => {
          if (keycity === 0) {
            setCityId({ value: itemcity.id, label: itemcity.name });
          }
          cityListArr.push({ value: itemcity.id, label: itemcity.name });
        });
        setCityList(cityListArr);
      }
    });
  }

  const handleCity = event => {
    setCityId({ value: event.value, label: event.label });
    getListJobAllFilter(1, name, countryid.value, salaryTo, industry.value, event.value, jobType.value, experience.value);
  }

  const handleJobType = event => {
    setJobType({ value: event.value, label: event.label });
    getListJobAllFilter(1, name, countryid.value, salaryTo, industry.value, cityId.value, event.value, experience.value);
  }

  const handleExperienceRequired = event => {
    setExperience({ value: event.value, label: event.label });
    getListJobAllFilter(1, name, countryid.value, salaryTo, industry.value, cityId.value, jobType.value, event.value);
  }

  const handleIndustry = event => {
    setIndustry(event);
  }

  const handleFilter = () => {
    getListJobAllFilter(1, name, countryid.value, salaryTo, industry.value, "", "", "",);
  }

  const handleSalaryTo = value => {
    setSalaryTo(value);
  }

  const getListJobAllFilter = (page, name, countryid, salary, industry, cityId, type, experience) => {
    getListJobAll(page, name, countryid, salary, industry, cityId, type, experience).then((res) => {
      const job = [];
      if (res.data && res.data.length) {
        setTotalPage(res.total);
        res.data.map((item, key) => {
          job.push({
            id: item.id,
            cityName: getNameCity(item.cityId),
            countryImage: checkIconCountry(countryData, item.cityId),
            companyImg: item.image ? item.image : checkIconIndustry(item.catId),
            jobTitle: item.title,
            companyName: item.company,
            location: item.workLocationAddress,
            dateNeeded: item.dateNeeded,
            iconJobPost: getJobIcon(item),
            fullTime: (item.jobType === 1 ? false : true),
            partTime: (item.jobType === 0 ? false : true),
            jobTypeText: (item.jobType === 1 ? jobTypeList[2].label : (item.jobType === 0 ? jobTypeList[1].label : jobTypeList[0].label)),
            salary: numberFormat(item.salaryFrom) + ' - ' + numberFormat(item.salaryTo) + ' ' + item.currency,
            addclassNameBookmark: false,
            badges: [],
            experience: convertExperience(item.experienceRequired)
          });
        });
        setJobVacancyList(job);
        if (job.length == 0) {
          setAlertNoData(t("managejob.ketquatimkiem"));
        } else {
          setAlertNoData('');
        }
      } else {
        setAlertNoData(t("managejob.ketquatimkiem"));
        setJobVacancyList([]);
      }
    });
  }

  const getNameCity = (cityId) => {
    let cityName = '';
    countryData && countryData.map((item, key) => {
      item.cities.map((itemCity, keyCity) => {
        if (itemCity.id == cityId) {
          cityName = itemCity.name;
        }
      })
    })
    return cityName;
  }

  const updateCurrentPage = (page) => {
    getListJobAllFilter(page, name, countryid.value, salaryTo, industry.value, cityId.value, jobType.value, experience.value);
  }

  return (
    <React.Fragment>
      <Col lg={12}>
        <div className="me-lg-5">
          <div className="job-list-header">
            <Form action="#">
              <Row className="g-2">
                <Col lg={3} md={6}>
                  <div className="filler-job-form">
                    <i className="uil uil-search me-1"></i>
                    <Input
                      value={name}
                      onChange={handleName}
                      type="search"
                      className="form-control filter-input-box"
                      id="exampleFormControlInput1"
                      placeholder="Tên công việc"
                      style={{ marginTop: "-10px" }}
                    />
                  </div>
                </Col>
                <Col lg={4} md={6}>
                  <div className="filler-job-form">
                    <i className="uil uil-location-point"></i>
                    <Select
                      options={countryList}
                      className="react-select-container choices selectForm__inner "
                      value={countryid}
                      onChange={handleCountry}
                      styles={colourStyles}
                      classNamePrefix="react-select"
                    />
                  </div>
                </Col>
                <Col lg={3} md={6}>
                  <div className="filler-job-form">
                    <i className="uil uil-briefcase-alt"></i>
                    <Select
                      options={industryList}
                      className="react-select-container choices selectForm__inner "
                      value={industry}
                      onChange={handleIndustry}
                      styles={colourStyles}
                      classNamePrefix="react-select"
                    />
                  </div>
                </Col>
                <Col lg={2} md={6}>
                  <Link onClick={() => handleFilter()} className="btn btn-primary w-100">
                    {t("managejob.timkiem")}
                  </Link>
                </Col>
              </Row>
              <Row>
                <Col>
                  <div className="side-bar mt-2 mt-lg-0 mb-3">
                    <div className="accordion" id="accordionExample">
                      <h2 className="accordion-header" style={{ width: '25%', float: 'right' }} id="experienceOne">
                        <Button
                          className="btn btn-primary w-100"
                          onClick={(e) => {
                            e.preventDefault();
                            setToggleFilter(!toggleFilter);
                          }}
                          role="button"
                          id="collapseExample"
                        >
                          <i className="uil uil-filter"></i> {t("managejob.locnangcao")}
                        </Button>
                      </h2>
                    </div>
                  </div>
                </Col>
              </Row>
              <Collapse isOpen={toggleFilter}>
                <Row className="g-2 mt-2">
                  <Col lg={3} md={6}>
                    <div className="filler-job-form">
                      <i className="uil uil-money-bill"></i>
                      <CurrencyInput
                        groupSeparator=','
                        decimalSeparator='.'
                        style={{ marginTop: "-10px" }}
                        placeholder={t("managejob.mucluongden_placeholder")}
                        id="salaryTo"
                        allowDecimals={false}
                        className="form-control filter-input-box"
                        value={salaryTo}
                        onValueChange={handleSalaryTo}
                        step={10}
                      />
                    </div>
                  </Col>
                  <Col lg={3} md={6}>
                    <div className="filler-job-form">
                      <i className="uil uil-home-alt"></i>
                      <Select
                        options={cityList}
                        className="react-select-container choices selectForm__inner "
                        value={cityId}
                        onChange={handleCity}
                        styles={colourStyles}
                        classNamePrefix="react-select"
                      />
                    </div>
                  </Col>
                  <Col lg={3} md={6}>
                    <div className="filler-job-form">
                      <Select
                        options={jobTypeListFilter}
                        className="react-select-container choices selectForm__inner "
                        value={jobType}
                        onChange={handleJobType}
                        styles={colourStyles}
                        classNamePrefix="react-select"
                      />
                    </div>
                  </Col>
                  <Col lg={3} md={6}>
                    <div className="filler-job-form">
                      <Select
                        options={experienceRequiredListFilter}
                        className="react-select-container choices selectForm__inner "
                        value={experience}
                        onChange={handleExperienceRequired}
                        styles={colourStyles}
                        classNamePrefix="react-select"
                      />
                    </div>
                  </Col>
                </Row>
              </Collapse>
            </Form>
          </div>
          <div>
            {alertNoData && <div className="section-title text-center">
              <h3 className="title">{alertNoData}</h3>
            </div>}
            {(!jobVacancyList.length && !alertNoData) ? (<PlaceholderJobFilter />) : <JobAllList jobList={jobVacancyList} />}
          </div>
        </div>
        <Pagination totalPage={totalPage} currentPage={updateCurrentPage} />
      </Col>
    </React.Fragment>
  );
};

export default JobVacancyList;
