import English from "../i18n/en.json";

export const UserSkill = (masterData, skill) => {
    let skillList = [];
    skill.map((item, key) => {
        let data = masterData.filter(c => c.id == item.industry)
        if (data.length) {
            skillList.push({ industry: data[0].id, labelField: data[0].nameEn })
        }
    })
    return skillList;
}

export const UserEducation = (education) => {
    const graduatestatusList = [
        { value: "1", label: English["tinhtrangtotnghiep.totnghiep"] },
        { value: "2", label: English["tinhtrangtotnghiep.danghoc"] },
        { value: "3", label: English["tinhtrangtotnghiep.baoluu"] },
    ];

    const educationLevelList = [
        { value: "0", label: English["capdo.tieuhoc"] },
        { value: "1", label: English["capdo.thcs"] },
        { value: "2", label: English["capdo.thpt"] },
        { value: "3", label: English["capdo.trungcap"] },
        { value: "4", label: English["capdo.caodang"] },
        { value: "5", label: English["capdo.daihoc"] },
        { value: "6", label: English["capdo.caohoc"] },
    ];

    let educationList = [];
    education.map((item, key) => {
        let data = graduatestatusList.filter(g => g.value == item.graduateStatus.value);
        let edu = educationLevelList.filter(e => e.value == item.typeSchool.value);
        if (data.length) {
            educationList.push({
                detail: item.detail, enrollmentMonth: item.enrollmentMonth, graduationMonth: item.graduationMonth, major: item.major,
                typeSchool: { value: edu[0].value, label: edu[0].label }, graduateStatus: { value: data[0].value, label: data[0].label }
            })
        }
    })

    return educationList;
}

export const userWorkExp = (work) => {
    const positionList = [
        { value: "1", label: English["chucvu.tonggiamdoc"] },
        { value: "2", label: English["chucvu.giamdoc"] },
        { value: "3", label: English["chucvu.phogiamdoc"] },
        { value: "4", label: English["chucvu.hdqt"] },
        { value: "5", label: English["chucvu.truongphong"] },
        { value: "6", label: English["chucvu.truongnhom"] },
        { value: "7", label: English["chucvu.nhanvien"] },
    ];

    let workList = [];
    work.map((item, key) => {
        let workData = positionList.filter(p => p.value == item.position.value);
        if (workData.length) {
            workList.push({
                company: item.company, jobDesc: item.jobDesc, startMonth: item.startMonth, endMonth: item.endMonth,
                position: { value: workData[0].value, label: workData[0].label }
            })
        }
    })
    return workList;
}