import React, { useState, useEffect } from "react";
import { Row, Col, DropdownToggle, DropdownMenu, DropdownItem, UncontrolledButtonDropdown } from "reactstrap";
import { Link } from "react-router-dom";
import { getAppliedJob, getAppliedFile, updateApplyStatus } from "../../../api/apiJob";
import { checkIconIndustry } from "../../../common/checkValidate/checkIconIndustry";
import PlaceholderJobFilter from "../../../common/placeholder/PlaceholderJobFilter";
import Pagination from "../../Jobs/Pagination/Pagination";
//Images Import
import userImage2 from "../../../assets/images/user/img-02.jpg";
import ModalUserInfo from "../../../common/modal/ModalUserInfo";
import ConfirmModal from "../../Component/ConfirmModal";
import { useTranslation } from "react-i18next";
const ManageJobApplyList = (props) => {
    const { t } = useTranslation("translation");
    const jobDetail = "/job/";
    let [jobAppliedList, setJobAppliedList] = useState([]);
    let [alertNoData, setAlertNoData] = useState('');
    let [totalPage, setTotalPage] = useState(1);
    let [currentPage, setCurrentPage] = useState(1);
    let [username, setUsername] = useState('');
    const [modalViewProfile, setModalViewProfile] = useState(false);
    const openModalViewProfile = (user) => {
        setModalViewProfile(true);
        setUsername(user.userName);
    }

    const closeModalViewProfile = () => {
        setModalViewProfile(false);
    }

    let [modalTitle, setModalTitle] = useState('');
    let [modalContent, setModalContent] = useState('');
    let [modalContent2, setModalContent2] = useState('');
    let [modalButton, setModalButton] = useState('');
    let [isOpen, setIsOpen] = useState(false);
    let [onClose, setOnClose] = useState(() => { });
    let [onConfirm, setOnConfirm] = useState(() => { });

    useEffect(() => {
        if (props.countryData) {
            loadAppliedJobData(1);
        }
    }, [props.countryData])

    const loadAppliedJobData = (page) => {
        setCurrentPage(page);
        const job = [];
        getAppliedJob(page).then((res) => {
            if (res.data) {
                setTotalPage(res.total);
                res.data.map((item, key) => {
                    job.push({
                        id: item.job.id,
                        avatar: item.employee.avatar ? item.employee.avatar : userImage2,
                        fullName: item.employee.fullName,
                        companyImg: item.job.image ? item.job.image : checkIconIndustry(item.job.catId),
                        jobTitle: item.job.title,
                        companyName: item.job.company,
                        files: getFileName(item.apply.files),
                        employee: item.employee,
                        message: item.apply.message,
                        applyId: item.apply.id,
                        status: item.apply.status,
                    });
                });
                setJobAppliedList(job);
                if (job.length == 0) {
                    setAlertNoData(t("managejob.ketquatimkiem"));
                } else {
                    setAlertNoData('');
                }
            } else {
                setAlertNoData(t("managejob.ketquatimkiem"));
                setJobAppliedList([]);
            }
        })
    }

    const getFileName = (file) => {
        let maplink = new Map();
        let fileName = file.split(",");

        fileName.map((item, key) => {
            let fileDescription = "";
            if (item == 1) {
                fileDescription = t("createcv.cv");
            } if (item == 2) {
                fileDescription = t("createcv.bangtotnghiep");
            } if (item == 3) {
                fileDescription = t("cv.bangdiemtotnghiep");
            } if (item == 4) {
                fileDescription = t("createcv.ccngoaingucaonhat");
            } if (item == 5) {
                fileDescription = t("createcv.hochieu");
            } if (item == 6) {
                fileDescription = t("createcv.visahoacthecutru");
            } if (item == 7) {
                fileDescription = t("createcv.banglaixe");
            } if (item == 8) {
                fileDescription = t("createcv.chungchitienganh");
            } if (item == 9) {
                fileDescription = t("createcv.chungchitiengnhat");
            } if (item == 10) {
                fileDescription = t("createcv.chungchitienghan");
            }
            if (fileDescription != "") {
                maplink.set(item, fileDescription);
            }
        });


        return maplink;
    }

    const updateCurrentPage = (page) => {
        loadAppliedJobData(page);
    }
    const downloadFile = (key, applyId) => {

        getAppliedFile(key, applyId).then((res) => {
            if (res.url) {
                window.open(res.url, '_blank');
            }
        });

    }
    const changeStatus = (applyId, status) => {
        setModalTitle('Xác nhận');
        setModalContent('Bạn có chắc chắn muốn thay đổi trạng thái ứng viên này sang:');
        if (status == 0) {
            setModalContent2('Chưa xem');
        } else if (status == 1) {
            setModalContent2('Đã nhận');
        } else if (status == 2) {
            setModalContent2('Đang phỏng vấn');
        } else if (status == 3) {
            setModalContent2('Đã phỏng vấn');
        } else if (status == 4) {
            setModalContent2('Tuyển dụng');
        } else if (status == 9) {
            setModalContent2('Từ chối');
        }
        setModalButton('Xác nhận');
        setOnConfirm(() => () => changeStatusAction(applyId, status));
        setOnClose(() => () => setIsOpen(false));
        setIsOpen(true);
    }
    const changeStatusAction = (applyId, status) => {

        updateApplyStatus({ Apply: applyId, applyStatus: status }).then((res) => {
            if (res.status) {
                loadAppliedJobData(currentPage);
            }
        });
        setIsOpen(false);
    }

    return (
        <React.Fragment>
            {modalViewProfile && <ModalUserInfo username={username} countryData={props.countryData} modalState={modalViewProfile} toggleModal={closeModalViewProfile} />}
            <Row className="align-items-center">
                <div>
                    {alertNoData && <div className="section-title text-center">
                        <h3 className="title">{alertNoData}</h3>
                    </div>}
                    <div className="mt-0">
                        <div className="p-4">
                            <Row className="align-items-center">
                                <Col md={3} className="text-center">
                                    <div className="mb-2 mb-md-0">
                                        <h5 className="fs-14 mb-1">
                                            <Link className="text-dark">
                                                {t("managejob.congviec")}
                                            </Link>
                                        </h5>
                                    </div>
                                </Col>
                                <Col md={7} className="text-left">
                                    <div className="mb-2 mb-md-0">
                                        <h5 className="fs-14 mb-1">
                                            <Link className="text-dark" >
                                                {t("managejob.ungvien")}
                                            </Link>
                                        </h5>
                                    </div>
                                </Col>
                                <Col md={2} className="text-center">
                                    <div className="mb-2 mb-md-0">
                                        <h5 className="fs-14 mb-1">
                                            <Link className="text-dark">
                                                {t("managejob.fileungtuyen")}
                                            </Link>
                                        </h5>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </div>
                    {(!jobAppliedList.length && !alertNoData) ? (<PlaceholderJobFilter />) : jobAppliedList && jobAppliedList.map((jobListDetails, key) => (
                        <div key={key} className="job-box card mt-4">
                            <div className="p-4">
                                <Row className="align-items-center">

                                    <Col md={3} style={{ textAlign: "left" }}>
                                        <div className="mb-2 mb-md-0">
                                            <h5 className="fs-18 mb-1">
                                                <Link to={jobDetail + jobListDetails.id} target="_blank" className="text-dark">
                                                    {jobListDetails.jobTitle}
                                                </Link>
                                            </h5>
                                        </div>
                                    </Col>
                                    <Col md={3} style={{ textAlign: "left" }}>
                                        <div className="mb-4 mb-md-0">
                                            <Link onClick={() => openModalViewProfile(jobListDetails.employee)} className="text-dark">
                                                <img
                                                    src={jobListDetails.avatar}
                                                    alt=""
                                                    className="img-fluid rounded-3"
                                                    style={{ width: '60px', height: '60px', objectFit: 'cover' }}
                                                />
                                                <h5 className="fs-18 mt-1">{jobListDetails.fullName}</h5>
                                            </Link>

                                        </div>
                                    </Col>

                                    <Col md={3} style={{ textAlign: "left" }}>
                                        <div className="mb-2 mb-md-0">

                                            <div>
                                                {jobListDetails.message}
                                            </div>
                                        </div>
                                    </Col>


                                    <Col md={2} className="text-center">
                                        <div className="mb-2 mb-md-0">

                                            {jobListDetails.files.size > 0 &&

                                                Array.from(jobListDetails.files.entries()).map(([key, value]) => (
                                                    <Link className="badge bg-success-subtle text-success fs-13 mt-1 mx-1" key={key} onClick={() => downloadFile(key, jobListDetails.applyId)} >
                                                        {value}
                                                    </Link>
                                                ))
                                            }


                                        </div>
                                    </Col>
                                    <Col md={1} style={{ textAlign: "center" }}>
                                        {jobListDetails.status == 0 ? <span className="badge bg-warning">Chưa xem</span>
                                            : jobListDetails.status == 1 ? <span className="badge bg-info">Đã nhận</span>
                                                : jobListDetails.status == 2 ? <span className="badge bg-info">Đang phỏng vấn</span>
                                                    : jobListDetails.status == 3 ? <span className="badge bg-info">Đã phỏng vấn</span>
                                                        : jobListDetails.status == 4 ? <span className="badge bg-success">Tuyển dụng</span>
                                                            : <span className="badge bg-danger">Từ chối</span>}
                                        <br />
                                        <UncontrolledButtonDropdown direction="down">
                                            <DropdownToggle caret
                                                type="button"
                                                tag="a"
                                                title=""
                                            >
                                                <span className=" d-inline-block text-center rounded-circle fs-20"><i className=" uil uil-ellipsis-h"></i></span>
                                            </DropdownToggle>
                                            <DropdownMenu container="body">

                                                {jobListDetails.status != 0 &&
                                                    <DropdownItem onClick={() => changeStatus(jobListDetails.applyId, 0)}>
                                                        <i className="uil "></i> {t("Chưa xem")}
                                                    </DropdownItem>
                                                }
                                                {jobListDetails.status != 1 &&
                                                    <DropdownItem onClick={() => changeStatus(jobListDetails.applyId, 1)}>
                                                        <i className="uil "></i> {t("Đã nhận")}
                                                    </DropdownItem>
                                                }
                                                {jobListDetails.status != 2 &&
                                                    <DropdownItem onClick={() => changeStatus(jobListDetails.applyId, 2)}>
                                                        <i className="uil "></i> {t("Đang phỏng vấn")}
                                                    </DropdownItem>
                                                }
                                                {jobListDetails.status != 3 &&
                                                    <DropdownItem onClick={() => changeStatus(jobListDetails.applyId, 3)}>
                                                        <i className="uil "></i> {t("Đã phỏng vấn")}
                                                    </DropdownItem>
                                                }
                                                {jobListDetails.status != 4 &&
                                                    <DropdownItem onClick={() => changeStatus(jobListDetails.applyId, 4)}>
                                                        <i className="uil "></i> {t("Tuyển dụng")}
                                                    </DropdownItem>
                                                }
                                                {jobListDetails.status != 9 &&
                                                    <DropdownItem onClick={() => changeStatus(jobListDetails.applyId, 9)}>
                                                        <i className="uil "></i> {t("Từ chối")}
                                                    </DropdownItem>
                                                }
                                            </DropdownMenu>
                                        </UncontrolledButtonDropdown>
                                    </Col>
                                </Row>
                            </div>
                        </div>
                    ))
                    }
                </div>
                <Pagination totalPage={totalPage} currentPage={updateCurrentPage} />
            </Row>
            <ConfirmModal isOpen={isOpen} onClose={onClose} onConfirm={onConfirm} modalTitle={modalTitle} modalContent={modalContent} modalContent2={modalContent2} modalButton={modalButton} />

        </React.Fragment>
    )
};

export default ManageJobApplyList;