import Japanese from "../i18n/jp.json";

export const UserSkill = (masterData, skill) => {
    let skillList = [];
    skill.map((item, key) => {
        let data = masterData.filter(c => c.id == item.industry)
        if (data.length) {
            skillList.push({ industry: data[0].id, labelField: data[0].nameJp })
        }
    })
    return skillList;
}

export const UserEducation = (education) => {
    const graduatestatusList = [
        { value: "1", label: Japanese["tinhtrangtotnghiep.totnghiep"] },
        { value: "2", label: Japanese["tinhtrangtotnghiep.danghoc"] },
        { value: "3", label: Japanese["tinhtrangtotnghiep.baoluu"] },
    ];
    let educationList = [];
    education.map((item, key) => {
        let data = graduatestatusList.filter(g => g.value == item.graduateStatus.value)
        if (data.length) {
            educationList.push({ detail: item.detail, enrollmentMonth: item.enrollmentMonth, graduationMonth: item.graduationMonth,
                major: item.major, graduateStatus: { value: data[0].value, label: data[0].label }
             })
        }
    })

    return educationList;
}